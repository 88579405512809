import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { UserRoleAdminGuard } from './shared/guards/user-role-admin-guard.service';
import { UserRoleClientGuard } from './shared/guards/user-role-client-guard.service';
import { SidenavComponent } from './sidenav/sidenav/sidenav.component';
import { NoResourceComponent } from './shared/404/no-resource.component';
import { FacebookLandingPageComponent } from './authentication/landing-pages/facebook-landing-page/facebook-landing-page.component';
import { GoogleLandingPageComponent } from './authentication/landing-pages/google-landing-page/google-landing-page.component';
import { DashboardTemplateResolver } from './reports/resolvers/dashboard-template.resolver';
import { ReportingResolver } from './reports/resolvers/reporting.resolver';
import { ShareDashboardAsLinkComponent } from './reports/components/share-dashboard-as-link/share-dashboard-as-link.component';
import { PixelResolver } from './pixel/resolver/pixel.resolver';
import { SuperUserFiledRoleGuardService } from './shared/guards/super-user-filed-role-guard.service';
import { AccountsGuard } from './shared/permisions/route-guards/accounts.guard';
import { AudienceGuard } from './shared/permisions/route-guards/audience.guard';
import { AdsManagerGuard } from './shared/permisions/route-guards/ads-manager.guard';
import { OptimizeGuard } from './shared/permisions/route-guards/optimize.guard';
import { PixelGuard } from './shared/permisions/route-guards/pixel.guard';
import { ProductCatalogGuard } from './shared/permisions/route-guards/product-catalog.guard';
import { ReportsGuard } from './shared/permisions/route-guards/reports.guard';
import { CampaignBuilderGuard } from './shared/permisions/route-guards/campaign-builder.guard';
import { KnowledgeHomeComponent } from './shared/knowledge-base/component-pieces/knowledge-home/knowledge-home.component';
import { KnowledgeSearchComponent } from './shared/knowledge-base/component-pieces/knowledge-search/knowledge-search.component';
import { SubmitTicketComponent } from './shared/knowledge-base/component-pieces/submit-ticket/submit-ticket.component';
import { TicketsViewComponent } from './sidenav/tickets-view/tickets-view.component';
import { ConnectionPageComponent } from './catalog/components/connection-page/connection-page.component';
import { ExportConnectionPageComponent } from './export-catalog/components/export-connection-page/export-connection-page.component';
import { ExportCatalogRedirectResolver } from './export-catalog/resolver/export-catalog-redirect.resolver';
import { UserBusinessOwnerGuard } from './shared/guards/user-business-owner.guard';
import { ClosePopupComponent } from './user-management/components/integrations/component-pieces/close-popup/close-popup.component';
import { SidenavGroupComponent } from './sidenav/sidenav/sidenav-group.component';

const routes: Routes = [
	{
		path: 'authentication',
		loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
	},
	{
		path: 'share-link/report/:hash',
		component: ShareDashboardAsLinkComponent
	},
	{ path: 'finish-connection', component: ClosePopupComponent },
	{ path: 'connection-successful', component: ConnectionPageComponent },
	{ path: 'export-success', component: ExportConnectionPageComponent, resolve: { dashboard: ExportCatalogRedirectResolver } },

	{
		path: '',
		component: SidenavGroupComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{ path: '', redirectTo: 'accounts', pathMatch: 'full' },
			{ path: 'welcome-facebook', canActivate: [UserRoleClientGuard], component: FacebookLandingPageComponent },
			{ path: 'welcome-google', canActivate: [UserRoleClientGuard], component: GoogleLandingPageComponent },
			{ path: 'support-tickets', canActivate: [UserRoleClientGuard], component: TicketsViewComponent },

			{
				path: 'auth-landing',
				loadChildren: () => import('./auth-landing-new/auth-landing-new.module').then(m => m.AuthLandingNewModule)
			},
			{
				path: 'accounts',
				canActivate: [UserBusinessOwnerGuard, AccountsGuard],
				loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
			},
			{
				path: 'audience',
				canActivate: [UserBusinessOwnerGuard, AudienceGuard],
				loadChildren: () => import('./audience/audience.module').then(m => m.AudienceModule)
			},
			{
				path: 'ads-manager',
				canActivate: [UserBusinessOwnerGuard, AdsManagerGuard],
				loadChildren: () => import('./ads-manager/ads-manager.module').then(m => m.AdsManagerModule)
			},
			{
				path: 'creative-builder',
				canActivate: [UserBusinessOwnerGuard, UserRoleClientGuard],
				loadChildren: () => import('./creative-builder/creative-builder.module').then(m => m.CreativeBuilderModule)
			},
			{
				path: 'creative-builder-back-office',
				canActivate: [UserRoleAdminGuard],
				loadChildren: () => import('./creative-builder/creative-builder.module').then(m => m.CreativeBuilderModule)
			},
			// {
			// 	path: 'shakespeare',
			// 	canActivate: [UserBusinessOwnerGuard, UserRoleClientGuard],
			// 	loadChildren: () => import('./shakespeare/shakespeare.module').then(m => m.ShakespeareModule)
			// },
			{
				path: 'gallery',
				canActivate: [UserBusinessOwnerGuard, UserRoleClientGuard],
				loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule)
			},
			{
				path: 'catalog',
				canActivate: [UserBusinessOwnerGuard, UserRoleClientGuard],
				loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule)
			},
			{
				path: 'catalog-issues',
				canActivate: [UserRoleClientGuard],
				loadChildren: () => import('./catalog-issues/catalog-issues.module').then(m => m.CatalogIssuesModule)
			},
			{
				path: 'catalog-overview',
				canActivate: [UserRoleClientGuard],
				loadChildren: () => import('./catalog-overview/catalog-overview.module').then(m => m.CatalogOverviewModule)
			},
			{
				path: 'export-catalog',
				canActivate: [UserRoleClientGuard],
				loadChildren: () => import('./export-catalog/export-catalog.module').then(m => m.ExportCatalogModule)
			},
			{
				path: 'optimize',
				canActivate: [UserBusinessOwnerGuard, OptimizeGuard],
				loadChildren: () => import('./optimise/optimise.module').then(m => m.OptimiseModule)
			},
			{
				path: 'pixel',
				canActivate: [UserBusinessOwnerGuard, PixelGuard],
				loadChildren: () => import('./pixel/pixel.module').then(m => m.PixelModule),
				resolve: {
					store: PixelResolver
				}
			},
			{
				path: 'product-catalog',
				canActivate: [ProductCatalogGuard],
				loadChildren: () => import('./product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule)
			},
			{
				path: 'reports',
				canActivate: [UserBusinessOwnerGuard, ReportsGuard],
				resolve: {
					dashboard: ReportingResolver,
					dashboardTemplate: DashboardTemplateResolver
				},
				loadChildren: () => import('./reports/reporting.module').then(m => m.ReportingModule)
			},
			{
				path: 'user-management',
				canActivate: [UserRoleClientGuard],
				loadChildren: () => import('./user-management/new-sprint/user-sprint.module').then(m => m.UserSprintModule)
			},
			{
				path: 'back-office',
				canActivate: [UserRoleAdminGuard],
				loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficeModule)
			},
			{
				path: 'styleguide',
				canActivate: [SuperUserFiledRoleGuardService],
				loadChildren: () => import('./styleguide/styleguide.module').then(m => m.StyleguideModule)
			},
			{
				path: 'campaign',
				canActivate: [UserBusinessOwnerGuard, CampaignBuilderGuard],
				runGuardsAndResolvers: 'always',
				loadChildren: () => import('./campaign-smart-create/campaign-smart-create.module').then(m => m.CampaignSmartCreateModule)
			},
			{
				path: 'smart-edit',
				canActivate: [UserBusinessOwnerGuard, CampaignBuilderGuard],
				runGuardsAndResolvers: 'always',
				loadChildren: () => import('./smart-edit/smart-edit.module').then(m => m.SmartEditModule)
			},
			{
				path: 'AAA',
				canActivate: [UserBusinessOwnerGuard, CampaignBuilderGuard],
				runGuardsAndResolvers: 'always',
				loadChildren: () => import('./AAA/AAA.module').then(m => m.AAAModule)
			},
			// {
			// 	canActivate: [UserBusinessOwnerGuard],
			// 	path: 'influencer',
			// 	loadChildren: () => import('./social-media-influencer/social-media-influencer.module').then(m => m.SocialMediaInfluencerModule)
			// },
			{
				canActivate: [UserBusinessOwnerGuard],
				path: 'google-campaign-builder',
				loadChildren: () => import('./google-campaign-builder/google-cb.module').then(m => m.GoogleCBModule)
			},
			{
				canActivate: [UserBusinessOwnerGuard],
				path: 'facebook-campaign-builder',
				loadChildren: () => import('./facebook-campaign-builder/facebook-campaign-builder.module').then(m => m.FacebookCampaignBuilderModule)
			}
		]
	},
	{
		path: 'home',
		canActivate: [AuthenticationGuard],
		component: KnowledgeHomeComponent,
		outlet: 'knowledge-base-modal'
	},
	{
		path: 'search',
		canActivate: [AuthenticationGuard],
		component: KnowledgeSearchComponent,
		outlet: 'knowledge-base-modal'
	},
	{
		path: 'submit-ticket',
		canActivate: [AuthenticationGuard],
		component: SubmitTicketComponent,
		outlet: 'knowledge-base-modal'
	},
	{ path: '404', component: NoResourceComponent },
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
