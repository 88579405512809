<!--dropdown select -->
<ng-select
	class="custom {{ category }}"
	[(ngModel)]="selectedValue"
	[multiple]="false"
	#ngSelect
	(window:resize)="onResize($event, ngSelect)"
	[formControl]="dropdownFormControl"
	[readonly]="readOnly"
	[items]="data"
	(scroll)="onScroll($event)"
	(scrollToEnd)="onScrollToEnd()"
	[searchable]="searchEnable"
	(search)="onSearch($event)"
	[bindLabel]="labelProperty"
	[bindValue]="bindProperty"
	[clearSearchOnAdd]="true"
	[closeOnSelect]="true"
	[clearable]="!hideClearButton"
	(focus)="autocompleteFocus()"
	(focusout)="setErrorMessage()"
	(change)="onChange($event)"
	(close)="onDropdownClose()"
	[compareWith]="compare"
	[ngClass]="{
		'input-error-message': dropdownFormControl.invalid && dropdownFormControl.touched,
		'input-success-message': dropdownFormControl.valid && dropdownFormControl.touched
	}"
	[ngClass]="{ fromOptimise: fromOptimise, custom: !fromOptimise, 'multiple-input-custom': true }"
	placeholder="{{ inputPlaceholder }}"
>
	<div>
		<ng-template (mouseleave)="onResize($event, ngSelect)" let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
			<div class="d-flex flex-column">
				<div
					class="right-check"
					[attr.disabled]="item.disabled"
					style="color: '#484848';"
					[style.font-size.px]="item.disabled ? '14' : '12'"
					[style.font-weight]="item.disabled ? 'bold' : 'normal'"
				>
					<div>
						{{ item.name }} {{ item?.type === 'city' ? ', ' + item?.region : '' }}
						{{ item?.type === 'city' || item?.type === 'region' ? ', ' + item?.countryName : '' }}
					</div>
					<div class="dot" *ngIf="item?.isActive"></div>
				</div>
				<div *ngIf="item?.subTitle" class="right-check" style="color: '#484848'; opacity: 0.8;">
					{{ item?.subTitle }}
				</div>
			</div>
		</ng-template>
	</div>
</ng-select>
<!--Error message -->
<span class="error-message" *ngIf="dropdownFormControl.invalid && dropdownFormControl.touched && !readOnly">{{ errorMessage }}</span>
