import * as fromRoot from '../../state/app.state';
import * as fromSharedState from './shared/shared.reducer';
import * as fromKeywordState from './keyword/keyword.reducer';
import { createFeatureSelector } from '@ngrx/store';
export interface GoogleCampaignBuilderState extends fromRoot.AppState {
	[fromSharedState.sharedFeatureKey]: fromSharedState.State;
	[fromKeywordState.keywordFeatureKey]: fromKeywordState.State;
}
export const initialState = {
	[fromSharedState.sharedFeatureKey]: fromSharedState.initialState,
	[fromKeywordState.keywordFeatureKey]: fromKeywordState.initialState
};

export const reducer = {
	[fromSharedState.sharedFeatureKey]: fromSharedState.reducer,
	[fromKeywordState.keywordFeatureKey]: fromKeywordState.reducer
};
export const selectGoogleCBState = createFeatureSelector<GoogleCampaignBuilderState>('google-cb');
