import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-guage',
	templateUrl: './guage.component.html',
	styleUrls: ['./guage.component.scss']
})
export class GuageComponent implements OnInit {
	@Input() public value = 0;
	constructor() {}

	ngOnInit(): void {}
}
