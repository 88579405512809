<div class="grid-section">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-gap-left"></div>
		<div class="grid-section-two-inner container">
			<div class="outside-form" [class.submitting]="submitting" *ngIf="!emailSent && !submitting">
				<div class="filed-logo">
					<img src="assets/logo/lolly-logo.svg" />
				</div>
				<div class="text-description">
					<p class="sign-in-link">
						Have an account?
						<a [routerLink]="'/authentication'" class="signup">Sign In</a>
					</p>
					<h2>Sign up to create your account</h2>
					<p>Please enter your details below to create your account</p>
				</div>

				<form class="login-form" [formGroup]="accountForm">
					<div class="margin-bottom-spacing">
						<outline-tb [control]="accountForm.get('name')" [validatorMessages]="validationMessages" label="Name*" class="input-box"></outline-tb>
					</div>
					<div class="margin-bottom-spacing">
						<outline-tb [control]="accountForm.get('surname')" [validatorMessages]="validationMessages" label="Surname*"></outline-tb>
					</div>
					<div class="margin-bottom-spacing">
						<outline-tb
							[control]="accountForm.get('company')"
							[validatorMessages]="validationMessages"
							label="Company Name*"
							class="input-box"
						></outline-tb>
					</div>
					<div class="margin-bottom-spacing">
						<outline-tb [control]="accountForm.get('email')" [validatorMessages]="validationMessages" label="Email*" class="input-box"></outline-tb>
					</div>
					<div class="margin-bottom-spacing form-row">
						<div class="left">
							<country-code-dropdown
								(keydown)="onKeydownEvent($event)"
								(selectCountry)="onSelectCountry($event)"
								[defaultFlagIcon]="defaultCountryCode"
								[control]="accountForm.get('dialcode')"
								[validatorMessages]="getValidationMessage()"
							></country-code-dropdown>
						</div>
						<div class="right">
							<outline-tb
								[control]="accountForm.get('phone')"
								[label]="'Phone Number*'"
								class=""
								[validatorMessages]="validationMessages"
							></outline-tb>
						</div>
					</div>

					<div
						class="checkbox-agree"
						[ngClass]="{
							'input-error-message': termsControl.invalid && termsControl.touched,
							'input-success-message': termsControl.valid && termsControl.touched,
							'input-disabled-message': termsControl.disabled
						}"
					>
						<label>
							<input [formControl]="termsControl" type="checkbox" value="agree" />
							I agree to Sansa’s
							<a href="https://www.filed.com/terms-conditions/" target="_blank">terms and conditions</a>
							policy.
						</label>

						<div class="checkbox-error" *ngIf="(termsControl.invalid && termsControl.touched) || (termsControl.invalid && submitted)">
							{{ errorMessage }}
						</div>
					</div>

					<div class="signup-error" *ngIf="error">
						{{ errorFormMessage }}
					</div>

					<div>
						<btn01 (click)="submitForm()" [disabled]="accountForm.invalid">Sign Up</btn01>
					</div>
				</form>
			</div>
			<div class="outside-email-sent" [class.submitting]="submitting" *ngIf="emailSent && !submitting">
				<div class="filed-logo">
					<img src="assets/logo/lolly-logo.svg" />
				</div>
				<div class="check-email">
					<img src="assets/icons/envelope.svg" />
					<h2>Check your email</h2>
					<p>
						<span>We’ve sent an email to {{ accountForm.get('email').value }}</span>
					</p>
					<p>Please confirm your email address with the link we’ve sent to the email you provided us with.</p>
				</div>

				<div>
					<btn01 (click)="resetEmail()">Send Again</btn01>
				</div>
			</div>
			<div class="loading" *ngIf="submitting">
				<lolly-spinning-loader></lolly-spinning-loader>
			</div>
		</div>
		<div class="grid-gap-right"></div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
