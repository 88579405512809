import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { getPermissions, getUserDetails, UserState } from '../state/user/user.reducer';
import { switchMap, take } from 'rxjs/operators';
import { MiscellaneousPermissions } from '../permisions/enums/miscellaneous-permissions';
import { Modules } from '../permisions/enums/modules';
import { SettingsPermissions } from '../permisions/enums/settings-permissions';

@Injectable({
	providedIn: 'root'
})
export class UserBusinessOwnerGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.authService.isLoggedIn()) {
			return this.userStore.pipe(
				select(getUserDetails),
				take(1),
				switchMap(userDetails => {
					// Backoffice and client employee handler
					let hasBackOfficePermission = false;
					let hasClientEmployeePermission = false;
					let backOfficeDefaultUrl = 'back-office';
					userDetails.Permissions.filter(module => module.module === Modules.Miscellaneous).forEach(module => {
						module.permissions.forEach(permission => {
							if (
								permission === MiscellaneousPermissions.IsAdmin ||
								permission === MiscellaneousPermissions.IsAccountManagerAdmin ||
								permission === MiscellaneousPermissions.IsSales ||
								permission === MiscellaneousPermissions.IsSalesAdmin ||
								permission === MiscellaneousPermissions.IsAccountManager
							) {
								if (permission === MiscellaneousPermissions.IsSales) {
									backOfficeDefaultUrl = 'back-office/role';
								}
								hasBackOfficePermission = true;
							}
						});
					});
					userDetails.Permissions.filter(module => module.module === Modules.Miscellaneous).forEach(module => {
						module.permissions.forEach(permission => {
							if (permission === MiscellaneousPermissions.IsClientEmployee) {
								hasClientEmployeePermission = true;
							}
						});
					});

					if (hasClientEmployeePermission) {
						return of(true);
					}

					if (hasBackOfficePermission) {
						this.router.navigate([backOfficeDefaultUrl]);
						return of(false);
					}
					// End Backoffice and client employee handler

					const fbId = userDetails.FacebookBusinessOwnerId;
					const googleBO = JSON.parse(localStorage.getItem('installedPlatorm'))?.find(value => {
						return value === 'Google';
					});

					if (state.url === '') {
						this.router.navigate(['/accounts']);
					}

					const firstTimeEntryBoCheck = localStorage.getItem('skipConnect');

					const isInfluencer = userDetails.Permissions.find(module => {
						return module.module === Modules.Influencer;
					});
					const isCreativeBuilder = userDetails.Permissions.find(module => {
						return module.module === Modules.CreativeBuilder;
					});

					if (!this.authService.allowedMegaRoutes$.value) {
						let allMegaPermissions = [];

						if (isInfluencer && !isCreativeBuilder) {
							// allMegaPermissions.push({
							// 	name: 'dexter',
							// 	title: 'Dexter',
							// 	icon: 'assets/icons/nav-menu/dexter-black.svg',
							// 	isSelected: false,
							// 	hoverOver: false
							// });
							allMegaPermissions.push({
								name: 'sansa',
								title: 'Sansa',
								icon: 'assets/icons/nav-menu/smi.svg',
								isSelected: true,
								hoverOver: false
							});
						} else if (isCreativeBuilder && !isInfluencer) {
							allMegaPermissions.push({
								name: 'dexter',
								title: 'Sansa',
								icon: 'assets/icons/nav-menu/dexter-black.svg',
								isSelected: false,
								hoverOver: false
							});
							allMegaPermissions.push({
								name: 'shakespeare',
								title: 'Shakespeare',
								icon: 'assets/icons/nav-menu/copy.svg',
								isSelected: false,
								hoverOver: false
							});
						} else if (isCreativeBuilder && isInfluencer) {
							allMegaPermissions.push({
								name: 'dexter',
								title: 'Sansa',
								icon: 'assets/icons/nav-menu/dexter-black.svg',
								isSelected: false,
								hoverOver: false
							});
							allMegaPermissions.push({
								name: 'sansa',
								title: 'Sansa',
								icon: 'assets/icons/nav-menu/smi.svg',
								isSelected: true,
								hoverOver: false
							});
							allMegaPermissions.push({
								name: 'shakespeare',
								title: 'Shakespeare',
								icon: 'assets/icons/nav-menu/copy.svg',
								isSelected: false,
								hoverOver: false
							});
						}

						this.authService.allowedMegaRoutes$.next(allMegaPermissions);
					}

					this.authService.setMegaRouteIndex$.next(0);

					this.authService.setParentRoute$.next(next.routeConfig.path);

					if (
						(!fbId || fbId === '') &&
						!googleBO &&
						!firstTimeEntryBoCheck &&
						next.routeConfig.path !== 'influencer' &&
						next.routeConfig.path !== 'catalog' &&
						next.routeConfig.path !== 'creative-builder'
					) {
						if (isInfluencer && !isCreativeBuilder) {
							this.router.navigate(['influencer']);
							return of(true);
						}
						this.router.navigate(['authentication/connect-channel']);
						return of(true);
					}

					if (
						next.routeConfig.path === 'influencer' ||
						next.routeConfig.path === 'creative-builder' ||
						next.routeConfig.path === 'optimize' ||
						next.routeConfig.path === 'catalog'
					) {
						if (next.routeConfig.path === 'creative-builder' || next.routeConfig.path === 'optimize' || next.routeConfig.path === 'catalog') {
							this.authService.setMegaRouteIndex$.next(0);
						} else if (next.routeConfig.path === 'influencer' && !isCreativeBuilder) {
							this.authService.setMegaRouteIndex$.next(0);
						} else if (next.routeConfig.path === 'influencer' && isCreativeBuilder) {
							this.authService.setMegaRouteIndex$.next(1);
						}
						return of(true);
					}
					// else if (next.routeConfig.path === 'connect-channel') {
					// 	return of(true);
					// }

					return of(true);
				})
			);
		}

		return of(false);
	}
}
