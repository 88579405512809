import { createSelector } from '@ngrx/store';
import { selectGoogleCBState } from '../index';

export const selectSharedState = createSelector(selectGoogleCBState, state => state.googleShared);

export const selectGCBSessionData = createSelector(selectSharedState, state => state.gcbSession);

export const selectCurrentSlide = createSelector(selectSharedState, state => state.currentSlide);

export const selectGCBAdActiveData = createSelector(selectSharedState, state => state.activeAdData);

export const selectGCBAdActiveAction = createSelector(selectSharedState, state => state.activeAdAction);

export const selectGCBActiveAdGroup = createSelector(selectSharedState, state => state.activeAdgroupData);

export const selectGCBAdFormArrayData = createSelector(selectSharedState, state => state.activeAdFormArrayData);

export const selectGCBAllAdGroup = createSelector(selectSharedState, state => state.allAdgroupData);

export const selectGCBActiveSmartCreateIndex = createSelector(selectSharedState, state => state.activeSmartCreateIndex);
export const selectAdPreview = createSelector(selectSharedState, state => state.adPreview);
export const selectAdExtensionPreview = createSelector(selectSharedState, state => state.adExtensionPreview);

export const selectGCBActiveExtensionData = createSelector(selectSharedState, state => state.activeExtensionData);

export const selectGCBUpdateExtensionData = createSelector(selectSharedState, state => state.updateExtensionData);

export const selectGCBActiveAdStrengthData = createSelector(selectSharedState, state => state.activeAdStrengthData);

export const selectGCBAdgroupsExhaustedData = createSelector(selectSharedState, state => state.adgroupsExhaustedData);

export const selectGCBMetricForecastData = createSelector(selectSharedState, state => state.metricForecastData);

export const selectGCBMetricForecastAdgroupsUsedData = createSelector(selectSharedState, state => state.metricForecastAdgroupsUsedData);

export const selectGCBDexterAssist = createSelector(selectSharedState, state => state.dexterAssist);
