export enum FcbSlideStepEnum {
	Campaign = 0,
	CampaignName = 1,
	SpecialAdCategory = 2,
	Budget = 3,
	Schedule = 4,
	AdSets = 5,
	Stage1 = 6,
	AdSetName = 7,
	Page = 8,
	Pixels = 9,
	Events = 10,
	GAAccount = 11,
	LandingUrl = 12,
	Stage2 = 13,
	Audience = 14,
	PersonalisedAds = 15,
	Placements = 16,
	ABSplit = 17,
	DynamicCreative = 18,
	AdLevels = 19,
	AdName = 20,
	AdFormat = 21,
	Media = 22,
	AdCopy = 23,
	Preview = 24
}
