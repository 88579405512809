import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { createReducer, on } from '@ngrx/store';
import { DataInterface } from 'src/app/shared/state/state-entities/data-interface.model';
import { IFCBSessionCreateResponse } from '../../models/fb-session-create.interface';
import * as SharedActions from './shared.actions';

export const sharedFeatureKey = 'facebookShared';

export interface State {
	fcbSession: DataInterface<IFCBSessionCreateResponse>;
	currentSlide: number;
}

export const initialState: State = {
	fcbSession: null,
	currentSlide: 0
};

export const reducer = createReducer(
	initialState,
	on(SharedActions.updateCurrentStep, (state, action) => ({ ...state, currentSlide: action.step })),
	on(SharedActions.loadSession, (state, action) => {
		return {
			...state,
			fcbSession: {
				...state.fcbSession,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.loadSessionSuccess, (state, action) => {
		return {
			...state,
			fcbSession: {
				...state.fcbSession,
				data: action.data
			}
		};
	}),
	on(SharedActions.loadSessionFailure, (state, action) => {
		return {
			...state,
			fcbSession: {
				...state.fcbSession,
				errorCode: action.error
			}
		};
	})
);
