<lolly-spinning-loader *ngIf="showSpinner" class="loading-indicator"></lolly-spinning-loader>

<div>
	<div class="grid-section">
		<div class="grid-section-one">
			<img class="grid-section-one-img" src="assets/images/login-background.png" />
		</div>
		<div class="grid-section-two">
			<div class="grid-gap-left"></div>
			<div class="grid-section-two-inner container">
				<div class="grid-inner-context">
					<div class="filed-logo">
						<img src="assets/logo/lolly-logo.svg" />
					</div>
					<div class="text-description">
						<h2>Get started with Sansa</h2>
						<p>Press continue to get started with Sansa</p>
					</div>

					<div class="flex-center">
						<btn21 (click)="getStarted()">
							Get Started
						</btn21>
					</div>
					<p class="logout">
						To change accounts or exit, please
						<span (click)="logout()">logout</span>
					</p>
				</div>
			</div>
			<div class="grid-gap-right"></div>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
