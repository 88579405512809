<div class="google-landing-page">
	<div class="glp-text-wrapper">
		<h3>Our teams are working behind the scenes to get this built.</h3>
		<h3>Check back soon.</h3>
	</div>
	<div class="glp-card-section">
		<div class="glp-card">
			<img class="glp-card-image" src="assets/icons/onboarding/manager-image.svg" alt="ads manager card" />
			<h3>View your ads performance with Sansa's Ads manager</h3>
			<app-button
				class="glp-btn"
				(buttonClicked)="onAdsManagerClick()"
				[buttonClass]="mainBtn.Primary"
				[buttonType]="typeBtn.Simple"
				[buttonLabel]="'GO TO ADS MANAGER'"
			></app-button>
		</div>
		<div class="glp-card">
			<img class="glp-card-image" src="assets/icons/onboarding/reporting-image.svg" alt="reporting card" />
			<h3>Build reports and dashboards using Sansa's Reporting Suite</h3>
			<app-button
				class="glp-btn"
				(buttonClicked)="onReportingClick()"
				[buttonClass]="mainBtn.Secondary"
				[buttonType]="typeBtn.Simple"
				[buttonLabel]="'GO RO REPORTING'"
			></app-button>
		</div>
	</div>
</div>
