import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ErrorMessageService } from 'src/app/shared/form-input/error-message.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';

@Component({
	selector: 'app-custom-dropdown-op5',
	templateUrl: './custom-dropdown-op5.component.html',
	styleUrls: ['./custom-dropdown-op5.component.scss']
})
export class CustomDropdownOp5Component implements OnInit {
	@Input() data: any[];
	@Input() inputPlaceholder: string;
	@Input() filterPlaceholder: string;
	@Input() locationType: string;
	@Input() mainFormCtrl = new FormControl();
	@Input() filterCtrl = new FormControl();

	@Output() selectionEvent = new EventEmitter<any>();

	public filteredItems = new ReplaySubject(1);

	private unsubscriber$: Subject<void> = new Subject<void>();
	constructor(public errorMessageService: ErrorMessageService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {}

	public onChange(event: any): void {
		this.selectionEvent.emit(event.option.value);
		this.filterCtrl.reset();
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	// public filterItems() {
	// 	if (!this.data) {
	// 		return;
	// 	}

	// 	let search = this.filterCtrl.value;
	// 	if (!search) {
	// 		this.filteredItems.next(this.data.slice());
	// 		return;
	// 	} else {
	// 		search = search.toLowerCase();
	// 	}

	// 	this.filteredItems.next(this.data.filter(item => item.name.toLowerCase().indexOf(search) > -1));
	// }
}
