import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { IGCBAdExtensionCalloutResponse, IGCBAdExtensionCallResponse, IGCBAdExtensionSiteResponse } from '../../model/gcb-adextension.interface';
import { IGCBActiveAdAction, IGCBAdgroup, IGCBAdGroupData, IGCBAds } from '../../model/gcb-adgroup-data.interface';
import { IGCBAdGroupSplitReq, IGCBAdGroupSplitResponse } from '../../model/gcb-adgroup-split.interface';
import { IGCBAdStrengthResponse } from '../../model/gcb-adstrength.interface';
import { IGCBCatzdKeywordsReq, IGCBCatzdKeywordsResponse } from '../../model/gcb-catzd-keywords.interface';
import { IGCBCreateOptionReq } from '../../model/gcb-create-option.interface';
import { IGCBMetricForecastAdroupsUsedResponse, IGCBMetricForecastResponse } from '../../model/gcb-metric-forecast.interface';
import { IGCBSaveCampaignObjReq } from '../../model/gcb-save-campaign-obj.interface';
import { IGCBSaveCampaignTypeReq } from '../../model/gcb-save-campaign-type.interface';
import { IGCBSaveKeywordsReq } from '../../model/gcb-save-keywords.interface';
import { IGCBSessionCreateResponse } from '../../model/gcb-session-create.interface';
import { GcbPreviewInterface, GcbStatePreviewInterface } from '../../model/gcb-preview-interface';

export const updateCurrentStep = createAction('[SharedGCB state] update current step', props<{ step: number }>());

export const loadShareds = createAction('[SharedGCB] Load Shareds');
export const clearShareState = createAction('[SharedGCB] clear shared store');
export const loadSharedsSuccess = createAction('[SharedGCB] Load Shareds Success', props<{ data: any }>());
export const loadSharedsFailure = createAction('[SharedGCB] Load Shareds Failure', props<{ error: any }>());

export const loadSession = createAction('[SharedGCB] Load Session');
export const loadSessionSuccess = createAction('[SharedGCB] Load Session Success', props<{ data: IGCBSessionCreateResponse }>());
export const loadSessionFailure = createAction('[SharedGCB] Load Session Failure', props<{ error: any }>());

export const updateCreationOption = createAction('[SharedGCB] Update Creation Option', props<{ data: IGCBCreateOptionReq }>());
export const updateCreationOptionSuccess = createAction('[SharedGCB] Update Creation Success', props<{ data: IGCBCreateOptionReq }>());
export const updateCreationOptionFailure = createAction('[SharedGCB] Update Creation Failure', props<{ error: any }>());

export const updateCampaignObj = createAction('[SharedGCB] Update Campaign Objective', props<{ data: IGCBSaveCampaignObjReq }>());
export const updateCampaignObjSuccess = createAction('[SharedGCB] Update Campaign Objective Success', props<{ data: IGCBSaveCampaignObjReq }>());
export const updateCampaignObjFailure = createAction('[SharedGCB] Update Campaign Objective Failure', props<{ error: any }>());

export const updateCampaignType = createAction('[SharedGCB] Update Campaign Type', props<{ data: IGCBSaveCampaignTypeReq }>());
export const updateCampaignTypeSuccess = createAction('[SharedGCB] Update Campaign Type Success', props<{ data: IGCBSaveCampaignTypeReq }>());
export const updateCampaignTypeFailure = createAction('[SharedGCB] Update Campaign Type Failure', props<{ error: any }>());

export const updateKeywords = createAction('[SharedGCB] Update Keywords', props<{ data: IGCBSaveKeywordsReq }>());
export const updateKeywordsSuccess = createAction('[SharedGCB] Update Keywords Success', props<{ data: IGCBSaveKeywordsReq }>());
export const updateKeywordsFailure = createAction('[SharedGCB] Update Keywords Failure', props<{ error: any }>());

export const updateCatzdKeywords = createAction('[SharedGCB] Update Catzd Keywords', props<{ data: IGCBCatzdKeywordsReq }>());
export const updateCatzdKeywordsSuccess = createAction('[SharedGCB] Update Catzd Keywords Success', props<{ data: IGCBCatzdKeywordsResponse }>());
export const updateCatzdKeywordsFailure = createAction('[SharedGCB] Update Catzd Keywords Failure', props<{ error: any }>());

export const updateAdGroupSplit = createAction('[SharedGCB] Update AdGroup Split', props<{ data: IGCBAdGroupSplitReq }>());
export const updateAdGroupSplitSuccess = createAction('[SharedGCB] Update AdGroup Split Success', props<{ data: IGCBAdGroupSplitReq }>());
export const updateAdGroupSplitFailure = createAction('[SharedGCB] Update AdGroup Split Failure', props<{ error: any }>());

export const updateActiveAdData = createAction('[SharedGCB] Update Active Ad Data', props<{ data: IGCBAds }>());

export const updateActiveAdAction = createAction('[SharedGCB] Update Active Ad Action', props<{ data: IGCBActiveAdAction }>());

export const updateActiveAdGroupData = createAction('[SharedGCB] Update Active Ad Group', props<{ data: IGCBAdgroup }>());

export const updateAdFormArrayData = createAction(
	'[SharedGCB] Update Active Form Array Data',
	props<{
		data: [{ headlineForm?: FormArray; update?: boolean }, { descriptionForm?: FormArray; update?: boolean }, { urlForm?: FormControl; update?: boolean }];
	}>()
);

export const updateAllAdGroupData = createAction('[SharedGCB] Update All Ad Group Data', props<{ data: IGCBAdgroup[] }>());
export const updateTempAdGroupData = createAction('[SharedGCB] Update temp Ad Group Data', props<{ data: IGCBAdgroup[] }>());

export const updateActiveSmartCreateIndex = createAction('[SharedGCB] Update Active Smart Create Index', props<{ data: number }>());
export const updateAdPreviews = createAction('[Shared state] update ad preview', props<{ data: any }>());
export const updateAdExtensionPreviews = createAction('[Shared state] update ad extension preview ', props<{ data: GcbStatePreviewInterface }>());

export const updateActiveExtensionData = createAction(
	'[SharedGCB] Update Active Extension Data',
	props<{
		data: {
			site: IGCBAdExtensionSiteResponse;
			callout: IGCBAdExtensionCalloutResponse;
			call: IGCBAdExtensionCallResponse;
		};
	}>()
);

export const updateExtensionData = createAction(
	'[SharedGCB] Update Extension Data',
	props<{ data: { site?: FormArray; callout?: FormArray; call?: FormGroup } }>()
);

export const updateActiveAdStrengthData = createAction('[SharedGCB] Update Active Ad Strength Data', props<{ data: IGCBAdStrengthResponse }>());

export const updateAdgroupsExhausted = createAction(
	'[SharedGCB] Update Adgroups Exhausted Data',
	props<{ data: { exhausted: boolean; breakpoint: boolean; totalAdgroups: number } }>()
);

export const updateMetricForecastData = createAction('[SharedGCB] Update Metric Forecast Data', props<{ data: IGCBMetricForecastResponse }>());

export const updateMetricForecastAdroupsUsedData = createAction(
	'[SharedGCB] Update Metric Forecast Adroups Used Data',
	props<{ data: IGCBMetricForecastAdroupsUsedResponse }>()
);

export const updateDexterAssist = createAction('[SharedGCB] Update Dexter Assist Data', props<{ data: boolean }>());
