<!-- <mat-form-field appearance="outline">
	<span matPrefix *ngIf="locationType" class="custom-prefix">{{ locationType }}</span>
	&nbsp;
	<mat-icon matPrefix>search</mat-icon>
	&nbsp;
	<mat-select matInput [formControl]="mainFormCtrl" [placeholder]="inputPlaceholder" #singleSelect (selectionChange)="onChange($event)">
		<mat-option>
			<ngx-mat-select-search [disableScrollToActiveOnOptionsChanged]="true" [formControl]="filterCtrl" [placeholderLabel]="filterPlaceholder"></ngx-mat-select-search>
		</mat-option>
		<mat-option *ngFor="let item of data" [value]="item">
			{{ item.name }}
		</mat-option>
	</mat-select>
</mat-form-field> -->

<mat-form-field class="w-100" appearance="outline">
	<span matPrefix *ngIf="locationType" class="custom-prefix">{{ locationType }}</span>
	<mat-icon matPrefix>search</mat-icon>
	<input type="text" matInput [formControl]="filterCtrl" [matAutocomplete]="auto" [placeholder]="inputPlaceholder" />
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChange($event)">
		<mat-option *ngFor="let option of data" [value]="option">
			{{ option.name }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
