import { createSelector } from '@ngrx/store';
import { selectFacebookCBState } from '..';

export const selectSharedState = createSelector(selectFacebookCBState, state => {
	return state.facebookShared;
});
export const selectGCBSessionData = createSelector(selectSharedState, state => state.fcbSession);

export const selectFbCurrentSlide = createSelector(selectSharedState, state => {
	return state.currentSlide;
});
