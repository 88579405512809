import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class BaseApiUrl {
	public static Users = environment.SharedServices.Users;
	public static UserVerification = environment.UserVerification;
	public static Contact = environment.SharedServices.Contact;
	public static GeneralSettings = environment.SharedServices.GeneralSettings;
	public static Subscription = environment.SharedServices.Subscription;
	public static UserViews = environment.SharedServices.GeneralSettings;
	public static FiledSupport = environment.SharedServices.Support;
	public static Payment = environment.Payment;
	public static BlueSnap = environment.BlueSnap;

	public static FacebookAccount = environment.Facebook.Account;
	public static GoogleAccountsMain = environment.Google.PythonAccountsMain;
	public static GoogleTuring = environment.Facebook.FacebookAccounts;
	// public static Audience = environment.Facebook.Audience;
	// public static FacebookMarketing = environment.Facebook.CampaignBuilder;
	// public static FacebookPythonCampaignBuilder = environment.Facebook.CampaignBuilderPython;
	public static FacebookAquaPythonCampaignBuilder = environment.Facebook.CampaignBuilderAquaPython;
	// public static CampaignBuilderSmartCreate = environment.Facebook.CampaignBuilderSmartCreate;
	public static FacebookDataPipeline = environment.Facebook.DataPipeline;
	// public static Pixel = environment.Facebook.Pixel;
	// public static PixelPython = environment.Facebook.PixelPython;
	public static FacebookApps = environment.Facebook.Apps;
	// public static Events = environment.Facebook.Pixel;
	public static Dexter = environment.Facebook.Dexter;
	public static DexterAnalytics = environment.Dexter.DexterAnalytics;
	public static DexterAnalyticsGoogle = environment.Dexter.DexterAnalyticsGoogle;
	public static OptimizationApi = environment.Dexter.Optimization;
	public static OptimizationApiGoogle = environment.Dexter.OptimizationGoogle;
	public static DexterSync = environment.Dexter.Sync;
	public static DexterGenesis = environment.Dexter.Genesis;
	public static DexterDashboard = environment.Dexter.DexterDashboard;
	public static GooglePythonCampaignBuilder = environment.Google.CampaignBuilderPython;
	public static FacebookPythonCampaignBuilder = environment.Facebook.CampaignBuilderPython;

	public static Schedules = environment.SharedMarketing.schedules;
	public static PyReportingfb = environment.SharedMarketing.ReportFbPY;
	public static PyReporting = environment.SharedMarketing.ReportingPY;
	public static Reporting = environment.SharedMarketing.Reporting;
	public static Assets = environment.SharedMarketing.Assets;

	public static GoogleAccounts = environment.Google.Accounts;
	public static GoogleInsights = environment.Google.CampaignManager;
	public static PythonGoogleAccounts = environment.Google.PythonAccounts;
	public static GoogleDataPipeline = environment.Google.DataPipeLine;

	public static HubSpot = environment.Misc.HubSpot;
	public static LogErrors = environment.Misc.LogErrors;

	public static CreativeBuilder = environment.CreativeBuilders.CreativeBuilder;
	public static CreativeBuilderSingleAdImage = environment.CreativeBuilders.SingleAdPreview;
	public static FacebookAsset = environment.SharedMarketing.Assets;

	public static ProductCatalog = environment.ProductCatalogs;
	public static PythonCatalogLambda = environment.PythonCatalogLambdas;
	public static SocialInfluencerPython = environment.SocialInfluencerApi.SocialInfluencerPython;
	public static SocialMessengerPython = environment.SocialInfluencerApi.SocialMessengerPython;
	public static SocialInfluencerCS = environment.SocialInfluencerApi.SocialInfluencerListAPI;
	public static SocialMessengerSocket = environment.SocialInfluencerApi.SocialMessengerSocket;
}
