<section class="vertical--stepper">
	<div
		(click)="stepClick.emit(step)"
		*ngFor="let step of steps; let i = index"
		[class.step-active]="step?.value === activeStep || step.disable"
		[class.step-complete]="step?.value < activeStep"
		class="step"
	>
		<div class="circle-wrapper">
			<div class="circle"></div>
		</div>
		<div class="title" [style.font-size.px]="'13'">
			<span>{{ step.label }}</span>
		</div>
	</div>
</section>
