import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ErrorMessageService } from 'src/app/shared/form-input/error-message.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { LanguageInterface } from 'src/app/facebook-campaign-builder/models/fb-adset-stage2-form.interface';

@Component({
	selector: 'app-custom-dropdown-op4',
	templateUrl: './custom-dropdown-op4.component.html',
	styleUrls: ['./custom-dropdown-op4.component.scss']
})
export class CustomDropdownOp4Component implements OnInit {
	@ViewChild('ngSelect') ngSelect: NgSelectComponent;
	@Input('category') public category: string;
	@Input() data: any[];
	@Input() inputPlaceholder: string;
	@Input() noDataFoundLabel: string;
	@Input() dropdownFormControl = new FormControl('');
	@Input() compareFunction: any;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() bindProperty = 'id';
	@Input() labelProperty = 'name';
	@Input() hideClearButton = false;
	@Input() enableAllActions = true;
	@Input() searchEnable = true;
	@Input() readOnly: boolean;
	@Input() pixelEventId: string = '';
	@Input() toolTip: boolean;
	@Input() rightCheckbox: boolean;
	@Input() fromOptimise: boolean;
	@Input() defaultSelectedValue: any;
	@Input() useCustom = false;
	@Output() selectionChange = new EventEmitter<any>();
	@Output() onClose = new EventEmitter();
	@Output() filters = new EventEmitter();

	filterText: string;

	public dropdownDataBuffer: any[];
	public bufferSize = 500;
	public numberOfItemsFromEndBeforeFetchingMore = 10;
	public loading = false;
	public tooltipMessage: string = '';
	public errorMessage: string;
	public isDestinationFocused: boolean;
	public independentFiler: boolean;

	public selectedValue: any;

	private unsubscriber$: Subject<void> = new Subject<void>();
	private selectedFilters: any[] = [];
	constructor(public errorMessageService: ErrorMessageService) {}

	ngOnInit() {
		// this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.dropdownFormControl);
		// if (this.defaultSelectedValue !== null && this.defaultSelectedValue !== undefined) {
		// 	this.selectedValue = this.defaultSelectedValue;
		// }
	}

	ngOnChanges(changes: SimpleChanges) {}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.dropdownFormControl);
	}

	public onChange(event: any): void {
		console.log(event);
		event[0].selected = !event[0].selected;
		this.dropdownFormControl.reset();
		this.selectionChange.emit(event[0]);
	}

	// [addTag] function should be a function expression
	// public createNew = (item: string): void => {
	// 	if (this.useCustom) {
	// 		let value = { name: item, value: item };
	// 		this.dropdownFormControl.setValue(value);
	// 		this.selectionChange.emit(value);
	// 		this.dropdownFormControl.setValue(value);
	// 		this.dropdownFormControl.setErrors(null);
	// 		this.ngSelect.close();
	// 	}
	// };

	public compare = (item, selected) => {
		return item.name == selected;
	};

	public onDropdownClose(): void {
		this.onClose.emit();
	}

	public onScrollToEnd(): void {
		this.increaseBuffer();
	}

	public onScroll(index: { start: number; end: number }): void {
		if (this.loading || this.data.length <= this.dropdownDataBuffer.length) {
			return;
		}

		if (index.end + this.numberOfItemsFromEndBeforeFetchingMore >= this.data.length) {
			this.increaseBuffer();
		}
	}

	public onResize($event: any, select: NgSelectComponent): void {
		select.close();
	}

	private resetBuffer(): void {
		this.bufferSize = 50;
		if (this.data) {
			this.dropdownDataBuffer = this.data.slice(0, this.bufferSize);
		}
	}

	private fillBuffer(fill = false): void {
		if ((fill || this.dropdownFormControl.value?.length) && this.data) {
			this.bufferSize = this.data.length;
			this.dropdownDataBuffer = this.data.slice(0, this.bufferSize);
		}
	}

	private increaseBuffer(): void {
		const len = this.dropdownDataBuffer.length;
		const more = this.data.slice(len, this.bufferSize + len);

		this.loading = true;
		// simulate backend API delay
		setTimeout(() => {
			this.loading = false;
			this.dropdownDataBuffer = this.dropdownDataBuffer.concat(more);
		}, 50);
	}

	public onSearch(searchItem: { term: string; items: any[] }): void {
		this.fillBuffer(true);
		if (!searchItem.term) {
			this.resetBuffer();
		}
	}
}
