<div class="grid-section">
	<div class="form-area">
		<div class="container">
			<div class="logo"><img alt="" src="assets/images/signup/sign-up.png" /></div>
			<div class="title">Hello and welcome to SANSA</div>
			<div class="sub-title">I am Sansa. I am here to assist you. Let's get you set up!</div>

			<div class="form-btn">
				<btn01 class="btn-signup" (onClick)="continueSignup()">
					Let's Go
				</btn01>
			</div>

			<div class="footer-link">
				Got a question?
				<a href="https://www.filed.com/contact-us-filed/">Don't worry. We are here to help you</a>
			</div>
		</div>
	</div>
</div>
