<div class="container">
	<div class="step1" *ngIf="allStep.step1 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="button">
			<img src="assets/icons/Dexter.svg" />
			<font03>Welcome to SANSA {{ decodeJwtToken.user_firstname }}.</font03>
			<button (click)="getStarted(allStep.step2)" class="btn-gcb-06">CONNECT ACCOUNTS</button>
			<!--	<app-connect-to-facebook-new (connectionResponse)="fbConnectionResponse($event)"></app-connect-to-facebook-new>
									<app-connect-to-facebook-new (connectionResponse)="fbConnectionResponse($event)"></app-connect-to-facebook-new> -->
		</div>
	</div>

	<div class="step2" *ngIf="allStep.step2 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body">
				<font04>Connect your accounts</font04>
				<font06>Select a channel and start setting you up for your account!</font06>
				<div class="box-wrapper">
					<div
						*ngFor="let channel of Channels"
						class="box"
						(click)="selectChannel(channel)"
						[ngClass]="{
							'selected-channel': selectedChannel?.name === channel.name,
							'box-disabled': channel.status === false,
							'disabled-channel': channel.name === 'Facebook' ? isFacebookConnected : isGoogleConnected || channel.status === false
						}"
					>
						<div>
							<div class="ch-name d-flex">
								<font05>{{ channel.name }}</font05>
								<font05 class="connected" *ngIf="channel.name === 'Facebook' && isFacebookConnected">CONNECTED</font05>
								<font05 class="connected" *ngIf="channel.name === 'Google' && isGoogleConnected">CONNECTED</font05>
								<font05 class="coming-soon" *ngIf="channel.status === false">COMING SOON</font05>
							</div>

							<div class="image">
								<img class="icon" src="{{ channel.icon }}" />
							</div>
							<font05>Your channel must:</font05>
							<div class="bullet-container">
								<div class="bullet-wrapper" *ngFor="let bullet of channel.bullets">
									<div class="dot">•</div>
									<font06>{{ bullet }}</font06>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="connect-buttons">
				<button type="button" class="connect-btn-grey" (click)="skipConnection()">Skip</button>
				<ng-container *ngIf="selectedChannel?.name === 'Google'">
					<img (mouseleave)="googleImg(0)" (mouseenter)="googleImg(1)" (click)="getStarted(allStep.step3)" [src]="googleImgSrc" />
				</ng-container>
				<ng-container *ngIf="selectedChannel?.name !== 'Google'">
					<btn07 class="connect-btn" *ngIf="selectedChannel" (onClick)="getStarted(allStep.step3)">CONNECT</btn07>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="step3" *ngIf="allStep.step3 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body">
				<div class="step3-body">
					<img *ngIf="selectedChannel.name !== 'Google'" src="assets/icons/Dexter-Facebook-Loading.svg" />
					<img *ngIf="selectedChannel.name === 'Google'" src="assets/icons/GoogleLoading.svg" />
				</div>
				<div class="text-body">
					<font03>Just one moment.</font03>
					<font07>We are gathering data from your {{ selectedChannel?.name }} account to help you get started with Sansa.</font07>
				</div>
			</div>
		</div>
	</div>
	<div class="step4" *ngIf="allStep.step4 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body" *ngIf="!googleSelected && !facebookSelected">
				<img class="img" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
				<font03>Connected!</font03>
				<font07>Your {{ selectedChannel?.name }} account is now connected with us.</font07>
				<font07>Would you like to connect another channel? Or start exploring?</font07>
				<div class="button">
					<btn07 (onClick)="getStarted(allStep.step2)">CONNECT ANOTHER CHANNEL</btn07>
					<btn07 (onClick)="getStarted(allStep.step5)">START EXPLORING</btn07>
				</div>
			</div>
			<div class="body" *ngIf="googleSelected || facebookSelected">
				<app-google-list *ngIf="googleSelected" (connectAccount)="lastSteponGoogle($event)" [googleAdsData]="googleAdsData"></app-google-list>
				<app-facebook-list
					*ngIf="facebookSelected"
					(connectAccount)="lastSteponFacebook($event)"
					[facebookAdsData]="facebookAdsData"
				></app-facebook-list>
			</div>
		</div>
	</div>
	<div class="step4" *ngIf="allStep.step4b === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body">
				<img class="img" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
				<font03>Connection Error!</font03>
				<font07>Connecting Google account has encounter an error.</font07>
				<font07>Would you like to try again or connect a different channel?</font07>
				<div class="button">
					<btn07 (onClick)="getStarted(allStep.step2)">CONNECT ANOTHER CHANNEL</btn07>
					<btn07 (onClick)="getStarted(allStep.step1)">RETRY</btn07>
				</div>
			</div>
		</div>
	</div>
	<div class="step5" *ngIf="allStep.step5 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con" [class.hasSkip]="!skipIsFired">
			<div style="margin-top: 120px;" class="body">
				<font04>Get Started...</font04>
				<font06>Select a product below, and let us walk you through how the product works.</font06>
				<div class="d-wrapper">
					<div class="box-wrapper">
						<div *ngFor="let item of planDetails">
							<div
								class="module"
								(click)="selectPage(item)"
								[class.selected-module]="pageSelected?.text === item.text"
								*ngIf="skipIsFired && item.skip"
							>
								<div class="img"><img src="assets/new-signup/dexter.png" /></div>
								<div class="title">
									<font05>{{ item.text }}</font05>
								</div>
								<div class="sub-title">
									<font07>{{ item.description }}</font07>
								</div>
							</div>
							<div class="module" (click)="selectPage(item)" [class.selected-module]="pageSelected?.text === item.text" *ngIf="!skipIsFired">
								<div class="img"><img src="assets/new-signup/dexter.png" /></div>
								<div class="title">
									<font05>{{ item.text }}</font05>
								</div>
								<div class="sub-title">
									<font07>{{ item.description }}</font07>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="connect-buttons">
				<button type="button" style="opacity: 0;" class="connect-btn-grey">Skip</button>
				<div>
					<btn04 class="connect-btn" [disabled]="!isPageSelected" *ngIf="!isPageSelected">CONTINUE</btn04>
					<btn01 class="connect-btn" *ngIf="isPageSelected" (onClick)="routeRequest()">CONTINUE</btn01>
				</div>
			</div>
		</div>
	</div>
</div>
