<div class="invoice-cont" #mainDiv id="mainDiv">
	<ng-container *ngIf="loading">
		<lolly-spinning-loader class="spinner"></lolly-spinning-loader>
	</ng-container>

	<ng-container *ngIf="!loading">
		<div class="d-flex justify-content-between">
			<btn01 (onClick)="downloadAsPdf()">Download Invoice</btn01>
			<button mat-icon-button mat-dialog-close>
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div class="invoice-overview" #dataToExport>
			<div class="invoice-main">
				<div class="d-flex justify-content-between">
					<div class="w-70p">
						<img src="assets/logo/lolly-logo.svg" />
						<font08>SANSA</font08>
						<font08>The Old Truman Brewery,</font08>
						<font08>91 Brick LaneLondon,</font08>
						<font08>London E1 6QL</font08>
						<font08>United Kingdom</font08>
					</div>
					<div class="w-30p">
						<font04>INVOICE</font04>
						<div class="d-flex">
							<font07>Invoice</font07>
							<font07># {{ InvoiceDetail.externalTransactionId ? InvoiceDetail.externalTransactionId : InvoiceDetail.billingId }}</font07>
						</div>
						<div class="d-flex">
							<font07>Invoice Date—</font07>
							<font07>{{ getDates }}</font07>
						</div>
						<div class="d-flex">
							<font07>Invoice Amount—</font07>
							<font07>{{ setCurrency }} ({{ InvoiceDetail.currencyCode }})</font07>
						</div>
						<!-- <div  class="d-flex">
							<font08>Customer ID— </font08>
							<font07>{{invoice.amount}}</font07>
						</div> -->
						<div class="d-flex">
							<font07>Billing Period—</font07>
							<font07>{{ getDates }}</font07>
						</div>
						<font06 class="paid" *ngIf="InvoiceDetail.status === 'Succeeded'">PAID</font06>
						<font06 class="unpaid" *ngIf="InvoiceDetail.status !== 'Succeeded'">UNPAID</font06>
					</div>
				</div>
				<div class="pt-30px d-flex justify-content-between">
					<div class="w-70p">
						<font04>BILLED TO</font04>
						<font04>{{ InvoiceDetail.firstName }} {{ InvoiceDetail.lastName }}</font04>
					</div>
					<div class="w-30p"></div>
				</div>
				<div class="pt-30px">
					<div>
						<div class="d-flex table-header">
							<div class="w-85p header-title">
								<font06>Item Description</font06>
							</div>

							<div class="w-15p header-title">
								<font06>Amount</font06>
							</div>
						</div>
						<div class="d-flex table-data">
							<div class="w-85p header-title">
								<font06>SANSA subscription</font06>
							</div>

							<div class="w-15p header-title">
								<font06>{{ setCurrency }}</font06>
							</div>
						</div>
					</div>
					<div class="table-footer pt-30px">
						<div class="w-25p d-flex justify-content-between">
							<font05>Total</font05>
							<font05>{{ setCurrency }}</font05>
						</div>
						<div class="w-25p d-flex justify-content-between">
							<font05>Amount Due</font05>
							<font05 *ngIf="InvoiceDetail.status !== 'Succeeded'">({{ InvoiceDetail.currencyCode }}) {{ setCurrency }}</font05>
							<font05 *ngIf="InvoiceDetail.status === 'Succeeded'">0.00</font05>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-address">
				<font08>Abstract Inc Limited, 91 Brick Lane, London, United Kingdom, E1 6QL, Vat Number GB227405909</font08>
			</div>
		</div>
	</ng-container>
</div>
