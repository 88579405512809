import { createAction, props } from '@ngrx/store';
import { KeywordTypeEnum } from '../../model/gcb-catzd-keywords.interface';
import { LoadedKeywordViewInterface } from '../../model/keyword-view-interface';

export const loadActiveKeywordView = createAction('[Keyword] Load Keywords views');
export const clearKeywordState = createAction('[Keyword] Clear keyword state');
export const loadKeywordViewAssistSuccess = createAction('[Keyword] Load Keywords views Success', props<{ data: LoadedKeywordViewInterface[] }>());
export const loadKeywordViewNonAssistSuccess = createAction(
	'[Keyword] Load Non Assists Keywords views Success',
	props<{ data: LoadedKeywordViewInterface[] }>()
);
export const loadKeywordsFailure = createAction('[Keyword] Load Keywords views Failure');
export const updateViewKeywordType = createAction('[Keyword] Update View Keyword Type', props<{ keywordType: KeywordTypeEnum }>());
export const updateViewKeywordAssist = createAction('[Keyword] Update View Keyword Assist', props<{ assist: boolean }>());
export const updateSelectedKeywords = createAction('[Keyword] Update Selected Keywords', props<{ keywords: any[] }>());
export const updateTempSelectedKeywords = createAction('[Keyword] Update temp Selected Keywords', props<{ keywords: any[] }>());
export const updateSelectAllKeywords = createAction('[Keyword] Update all Selected Keywords', props<{ isSelectAll: boolean }>());
export const loadBudgetValue = createAction('[Keyword] Load budget value', props<{ sessionId: string }>());
export const updateBudgetValue = createAction('[Keyword] Update budget value', props<{ budget: number }>());
export const loadSelectedKeywordCount = createAction('[Keyword] Load Selected Keywords counts', props<{ sessionId: string }>());
export const updateSelectedKeywordCounts = createAction(
	'[Keyword] Update selected keyword counts value',
	props<{ selectedKeyword: number; totalKeywords: number }>()
);
export const loadTotalDailyBudgetUtilized = createAction('[Keyword] load Total daily budgets', props<{ sessionId: string; totalDailyBudget: number }>());
export const loadKeywordForecast = createAction('[Keyword] load keywords forecast', props<{ sessionId: string }>());
export const updateTotalDailyBudgetUtilized = createAction('[Keyword] update Total daily budgets', props<{ totalBudgetUtilised: number }>());
export const updateTotalSelectedDailyBudgetUtilized = createAction('[Keyword] update selected Total daily budgets', props<{ totalBudgetUtilised: number }>());
export const updateKeywordForecast = createAction('[Keyword] update keyword forecasts', props<{ estimatedClicks: number; estimatedConversions: number[] }>());
export const updateSelectedKeywordForecast = createAction(
	'[Keyword] update selected keyword forecasts',
	props<{ estimatedClicks: number; estimatedConversions: number[] }>()
);
export const clearBudgetAndForecast = createAction('[Keyword] clear budget and forecast');
export const loadSavedSelectedKeywords = createAction('[Keyword actions] load svaed selected keywords', props<{ sessionId: string }>());
export const UpdateSavedSelectedKeywords = createAction('[Keyword actions] load svaed selected keywords', props<{ data: any[] }>());
