import { SelectedAdAccountModel } from '../../shared/state/state-entities/cached-ad-accounts.model';
import { WelcomePageModel } from '../../shared/welcome-page/welcome-page.model';
import moment from 'moment';
import { NewRecommendationInterface } from '../../shared/recommendations-new-template/models/new-recommendation.interface';
import { ChannelsEnum } from '../enums/channels.enum';
import { Recommendation } from '../models/recommendation.model';
import { NewOptimizationLevelsEnum } from '../models/new-optimization-levels.enum';
import { ILabsRecommendations, ILabsRecommendation, ILabsStructureTree } from '../labs-page/labs-recommendations/labs-recommendations.interface';
import { ILabCampaignGroup, ILabType } from '../models/labs-recommendation.interface';
import { OptimizeRecommendationStatus } from '../models/optimize-recommendations-response.interface';
import { IDexterHealthStatus } from '../models/dexter-health.interface';

const maxDaysForWheelsScreen = 20;

export interface ICampaignAdsetNamingDisplay {
	img: string;
	text: string;
}
export class OptimizeHelper {
	public static getInsightTable(recommendation: NewRecommendationInterface | Recommendation): string {
		if (recommendation.channel === ChannelsEnum.Facebook) {
			switch (recommendation.level) {
				case NewOptimizationLevelsEnum.Ad:
					return 'vAdInsights';
				case NewOptimizationLevelsEnum.AdSet:
					return 'vAdSetInsights';
				case NewOptimizationLevelsEnum.Campaign:
					return 'vCampaignInsights';
			}
		} else if (recommendation.channel === 'google') {
			switch (recommendation.level) {
				case NewOptimizationLevelsEnum.Ad: {
					return 'AdPerformanceReport';
				}
				case NewOptimizationLevelsEnum.AdGroup: {
					switch (recommendation.breakdown.name) {
						case 'gender':
							return 'AdGroupGenderPerformanceReport';
						case 'age':
							return 'AdGroupAgeRangePerformanceReport';
						default:
							return 'AdGroupPerformanceReport';
					}
				}
				case NewOptimizationLevelsEnum.Campaign:
					switch (recommendation.breakdown.name) {
						case 'gender':
							return 'CampaignGenderPerformanceReport';
						case 'age':
							return 'CampaignAgeRangePerformanceReport';
						default:
							return 'CampaignPerformanceReport';
					}
			}
		}
	}

	public static isNewAccount(adAccount: SelectedAdAccountModel): boolean {
		const accountStartDate = moment(adAccount.adAccount.createdAt);
		const today = moment();
		const accountAge = today.diff(accountStartDate, 'days');
		return accountAge <= maxDaysForWheelsScreen;
	}

	public static getWelcomePageModel(): WelcomePageModel {
		const model = new WelcomePageModel();
		model.img.left = '/assets/icons/welcome-pages/no-recommendations.svg';
		model.title = 'No Recommendations Yet';
		model.subtitle = 'Please check back in a short while.';
		model.showRightCard = false;
		model.showButtons = false;
		return model;
	}

	public static getNoSelectionModel(): WelcomePageModel {
		const model = new WelcomePageModel();
		model.img.left = '/assets/icons/welcome-pages/no-recommendations.svg';
		model.title = 'You have not made a selection';
		model.subtitle = 'Make a priority selection to get recommendations.';
		model.showRightCard = false;
		model.showButtons = false;
		return model;
	}

	public static getInputDataModel(inputs?: OptimizeRecommendationStatus): WelcomePageModel {
		const model = new WelcomePageModel();
		model.img.left = '/assets/icons/welcome-pages/no-recommendations.svg';
		model.title = inputs ? inputs.titleText : '';
		model.subtitle = inputs ? inputs.mainText : '';
		model.showRightCard = false;
		model.showButtons = false;
		return model;
	}

	public static getLabsHomePageNoDataModel(inputs?: OptimizeRecommendationStatus): WelcomePageModel {
		const model = new WelcomePageModel();
		model.img.left = 'assets/images/optimize/undraw_science.svg';
		model.title = inputs ? inputs.titleText : '';
		model.subtitle = inputs ? inputs.mainText : '';
		model.showRightCard = false;
		model.showButtons = false;
		return model;
	}

	public static getLabsHomePageModel(buttons: boolean): WelcomePageModel {
		const model = new WelcomePageModel();
		model.img.left = 'assets/images/optimize/undraw_science.svg';
		model.title = 'Welcome to Sansa Labs';
		model.subtitle = 'It seems like your campaigns are doing great so far';
		model.buttonText.left = 'Get Started';
		model.showRightCard = false;
		model.showButtons = buttons;
		return model;
	}

	public static getHealthHomePageModel(inputs?: IDexterHealthStatus): WelcomePageModel {
		let formedTitleText = '';
		if (inputs?.links && inputs?.hyperTextLink) {
			const formHyperlink = `<a href="${inputs.links}" target="_blank" >${inputs.hyperTextLink}</a>`;
			const formTextWithUrl = inputs.mainText.replace(inputs.hyperTextLink, formHyperlink);
			formedTitleText = formTextWithUrl;
		} else {
			formedTitleText = inputs?.mainText;
		}
		const model = new WelcomePageModel();
		model.img.left = 'assets/icons/optimize/dexter-health.svg';
		model.title = inputs ? inputs.titleText : '';
		model.subtitle = formedTitleText;
		model.showRightCard = false;
		model.showButtons = false;
		return model;
	}

	public static getWelcomeSyncPageModel(title: string, subtitle: string, buttonDisabled?: boolean): WelcomePageModel {
		const model = new WelcomePageModel();
		model.img.left = '/assets/icons/welcome-pages/no-recommendations.svg';
		model.title = title;
		model.subtitle = subtitle;
		model.buttonText.left = 'Get Started';
		model.buttonDisabled = buttonDisabled ? buttonDisabled : false;
		model.showRightCard = false;
		model.showButtons = true;
		return model;
	}

	public static getLabsRecommendationsModel(recommendationsData: NewRecommendationInterface[]): ILabsRecommendation[] {
		const labsRecommendations: ILabsRecommendation[] = [];
		const buttonAction = (): string => {
			return '';
		};
		const formatCampaignAdsetLabel = (data: NewRecommendationInterface): ICampaignAdsetNamingDisplay[] => {
			const displays: ICampaignAdsetNamingDisplay[] = [];
			if (data.level === 'campaign') {
				const obj = {
					img: 'assets/icons/optimize/megaphone.svg',
					text: data.campaignName
				};
				displays.push(obj);
			} else {
				displays.push(
					{
						img: 'assets/icons/optimize/visibility-adset.svg',
						text: data.structureName
					},
					{
						img: 'assets/icons/optimize/megaphone.svg',
						text: data.campaignName
					}
				);
			}
			return displays;
		};
		recommendationsData.forEach(data => {
			if (data.algorithmType === 'labs_lookalike_strategy') {
				const lookalike = {
					recommendationId: data.recommendationId,
					title: data.title,
					shortName: 'lookalike',
					titleImg: 'assets/images/optimize/awesome-users.svg',
					topLeftText: data.analysis,
					bottomOpen: false,
					topLeftAction: formatCampaignAdsetLabel(data),
					rightAction: buttonAction,
					rightButtonText: 'Actions',
					rightButtonIconText: 'plus',
					bottomText: data.subText,
					bottomImg: 'assets/images/optimize/lookalike.svg',
					quoteText: data.quote,
					adsetId: data.structureId,
					adsetName: data.structureName,
					applyTooltip: data.applyTooltip,
					campaignId: data.campaignId,
					campaignName: data.campaignName,
					sourceInterests: data.sourceInterests,
					suggestedInterests: data.suggestedInterests,
					hiddenInterests: data.hiddenInterests
				};
				labsRecommendations.push(lookalike);
			} else if (data.algorithmType === 'labs_hidden_interests_strategy') {
				const changeInterest = {
					recommendationId: data.recommendationId,
					title: data.title,
					shortName: 'changeInterest',
					titleImg: 'assets/images/optimize/visibility-24px.svg',
					topLeftText: data.analysis,
					bottomOpen: false,
					topLeftAction: formatCampaignAdsetLabel(data),
					rightAction: buttonAction,
					rightButtonText: 'Choose Interests',
					rightButtonIconText: '',
					bottomText: data.subText,
					bottomImg: 'assets/images/optimize/change-interest.svg',
					quoteText: data.quote,
					adsetId: data.structureId,
					adsetName: data.structureName,
					applyTooltip: data.applyTooltip,
					campaignId: data.campaignId,
					campaignName: data.campaignName,
					sourceInterests: data.sourceInterests,
					suggestedInterests: data.suggestedInterests,
					hiddenInterests: data.hiddenInterests
				};
				labsRecommendations.push(changeInterest);
			} else if (data.algorithmType === 'labs_new_interests_strategy') {
				const changeInterest = {
					recommendationId: data.recommendationId,
					title: data.title,
					shortName: 'newInterest',
					titleImg: 'assets/images/optimize/visibility-24px.svg',
					topLeftText: data.analysis,
					bottomOpen: false,
					topLeftAction: formatCampaignAdsetLabel(data),
					rightAction: buttonAction,
					rightButtonText: 'Choose Interests',
					rightButtonIconText: '',
					bottomText: data.subText,
					bottomImg: 'assets/images/optimize/change-interest.svg',
					quoteText: data.quote,
					adsetId: data.structureId,
					adsetName: data.structureName,
					applyTooltip: data.applyTooltip,
					campaignId: data.campaignId,
					campaignName: data.campaignName,
					sourceInterests: data.sourceInterests,
					suggestedInterests: data.suggestedInterests,
					hiddenInterests: data.hiddenInterests
				};
				labsRecommendations.push(changeInterest);
			} else if (data.algorithmType === 'labs_retargeting_strategy') {
				const retarget = {
					recommendationId: data.recommendationId,
					title: data.title,
					shortName: 'retarget',
					titleImg: 'assets/images/optimize/feather-target.svg',
					topLeftText: data.analysis,
					bottomOpen: false,
					topLeftAction: formatCampaignAdsetLabel(data),
					rightAction: buttonAction,
					rightButtonText: 'Actions',
					rightButtonIconText: 'plus',
					bottomText: data.subText,
					bottomImg: 'assets/images/optimize/retarget.svg',
					quoteText: data.quote,
					adsetId: data.structureId,
					adsetName: data.structureName,
					applyTooltip: data.applyTooltip,
					campaignId: data.campaignId,
					campaignName: data.campaignName,
					sourceInterests: data.sourceInterests,
					suggestedInterests: data.suggestedInterests,
					hiddenInterests: data.hiddenInterests
				};
				labsRecommendations.push(retarget);
			}
		});

		return labsRecommendations;
	}

	public static getLabsStructureModel(structureData: ILabsStructureTree[]): ILabCampaignGroup[] {
		const labCampaignGroup: ILabCampaignGroup[] = [];
		structureData.forEach(data => {
			if (data.adsets.length > 0) {
				data.adsets.forEach(adset => {
					const labCampaign: ILabCampaignGroup = {
						campaignName: data.campaignName,
						campaignId: data.campaignId,
						name: adset.adsetName,
						id: adset.adsetId
					};
					labCampaignGroup.push(labCampaign);
				});
			} else {
				const labCampaign: ILabCampaignGroup = {
					campaignName: data.campaignName,
					campaignId: data.campaignId
				};
				labCampaignGroup.push(labCampaign);
			}
		});
		return labCampaignGroup;
	}

	public static createLabTypes(): ILabType[] {
		const typeLookalike = {
			name: 'labs_lookalike_strategy',
			shortName: 'Look Alike',
			image: 'assets/images/optimize/awesome-users.svg',
			isSelected: true
		};
		const typeHidden = {
			name: 'labs_hidden_interests_strategy',
			shortName: 'Interests Targeting',
			image: 'assets/images/optimize/visibility-24px.svg',
			isSelected: true
		};
		const typeRetarget = {
			name: 'labs_retargeting_strategy',
			shortName: 'Retargeting',
			image: 'assets/images/optimize/feather-target.svg',
			isSelected: true
		};
		return [typeLookalike, typeHidden, typeRetarget];
	}
}
