<div class="grid-section">
	<div class="form-area">
		<div class="container">
			<div class="sub-title back">
				<a [routerLink]="'/authentication/register-email'" class="signin">
					<i class="k-icon k-i-arrow-chevron-left"></i>
					Back
				</a>
			</div>
			<div class="logo"><img width="40%" alt="Sansa" src="assets/logo/lolly-logo.svg" /></div>
			<div class="title">Personal Details</div>

			<div class="form">
				<div class="bottom-spacing">
					<outline-tb
						[control]="accountForm.controls['name']"
						[validatorMessages]="validationMessages"
						class="input-box"
						(change)="onFormNameChange($event)"
						label="Enter your First Name*"
					></outline-tb>
				</div>
				<div class="bottom-spacing">
					<outline-tb
						[control]="accountForm.controls['lastname']"
						[validatorMessages]="validationMessages"
						label="Enter your Last Name*"
						class="input-box"
						(change)="onFormLastNameChange($event)"
					></outline-tb>
				</div>
				<div class="bottom-spacing">
					<intl-outline-tb
						(selectCountry)="onSelectCountry($event)"
						[control]="accountForm.get('phone')"
						[defaultFlagIcon]="defaultCountryCode"
						[validatorMessages]="validationMessages"
						(change)="onFormNumberChange($event)"
						label="Enter your contact number*"
						class="input-box telephone"
					></intl-outline-tb>
				</div>
				<div class="bottom-spacing">
					<outline-tb
						[control]="accountForm.controls['companyName']"
						label="Enter your company Name"
						class="input-box"
						(change)="onFormCompanyNameChange($event)"
					></outline-tb>
				</div>
			</div>

			<div class="form-btn">
				<btn04 class="btn-signup" *ngIf="accountForm.invalid" [disabled]="accountForm.invalid">Continue</btn04>

				<btn01 class="btn-signup" (onClick)="continueSignup()" *ngIf="accountForm.valid">
					Continue
				</btn01>
			</div>

			<div class="footer left">
				Got a question?
				<a href="https://www.filed.com/contact-us-filed/" target="_blank" class="signin">Don't worry. We are here to help.</a>
			</div>
		</div>
	</div>
</div>
