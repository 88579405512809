<ng-select
	class="{{ category }}"
	[(ngModel)]="selectedValue"
	name="selectedValue"
	#ngSelect
	(window:resize)="onResize($event, ngSelect)"
	[formControl]="dropdownFormControl"
	[readonly]="readOnly"
	[items]="data"
	(scroll)="onScroll($event)"
	(scrollToEnd)="onScrollToEnd()"
	[searchable]="searchEnable"
	(search)="onSearch($event)"
	[bindLabel]="labelProperty"
	[bindValue]="bindProperty"
	[clearSearchOnAdd]="true"
	[closeOnSelect]="false"
	[clearable]="!hideClearButton"
	(focus)="autocompleteFocus()"
	(focusout)="setErrorMessage()"
	multiple="false"
	(close)="onDropdownClose()"
	[compareWith]="compare"
	(change)="onChange($event)"
	[ngClass]="{
		'input-error-message': dropdownFormControl.invalid && dropdownFormControl.touched,
		'input-success-message': dropdownFormControl.valid && dropdownFormControl.touched
	}"
	[ngClass]="{ fromOptimise: fromOptimise, custom: !fromOptimise, 'multiple-input-custom': true }"
	notFoundText="{{ noDataFoundLabel }}"
	placeholder="{{ inputPlaceholder }}"
>
	<div>
		<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
			<!-- <input [ngModelOptions]="{ standalone: true }" [ngModel]="item$.selected" id="item-{{ index }}" type="checkbox" /> -->
			{{ item.name }}
		</ng-template>
	</div>
</ng-select>
<span class="error-message" *ngIf="dropdownFormControl.invalid && dropdownFormControl.touched && !readOnly">{{ errorMessage }}</span>
