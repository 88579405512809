import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Action, createReducer, on } from '@ngrx/store';
import { DataInterface } from 'src/app/shared/state/state-entities/data-interface.model';
import { IGCBAdExtensionCalloutResponse, IGCBAdExtensionCallResponse, IGCBAdExtensionSiteResponse } from '../../model/gcb-adextension.interface';
import { IGCBActiveAdAction, IGCBAdgroup, IGCBAdGroupData, IGCBAds } from '../../model/gcb-adgroup-data.interface';
import { IGCBAdGroupSplitReq } from '../../model/gcb-adgroup-split.interface';
import { IGCBAdStrengthResponse } from '../../model/gcb-adstrength.interface';
import { IGCBCatzdKeywordsResponse } from '../../model/gcb-catzd-keywords.interface';
import { IGCBCreateOptionReq } from '../../model/gcb-create-option.interface';
import { IGCBMetricForecastAdroupsUsedResponse, IGCBMetricForecastResponse } from '../../model/gcb-metric-forecast.interface';
import { IGCBSaveCampaignObjReq } from '../../model/gcb-save-campaign-obj.interface';
import { IGCBSaveCampaignTypeReq } from '../../model/gcb-save-campaign-type.interface';
import { IGCBSaveKeywordsReq } from '../../model/gcb-save-keywords.interface';
import { IGCBSessionCreateResponse } from '../../model/gcb-session-create.interface';
import * as SharedActions from './shared.actions';
import { GcbPreviewInterface, GcbStatePreviewInterface } from '../../model/gcb-preview-interface';

export const sharedFeatureKey = 'googleShared';

export interface State {
	loading: boolean;
	gcbSession: DataInterface<IGCBSessionCreateResponse>;
	updatedCreationOption: DataInterface<IGCBCreateOptionReq>;
	updatedCampaignObjective: DataInterface<IGCBSaveCampaignObjReq>;
	updatedCampaignType: DataInterface<IGCBSaveCampaignTypeReq>;
	updateKeywords: DataInterface<IGCBSaveKeywordsReq>;
	updateCatzdKeywords: DataInterface<IGCBCatzdKeywordsResponse>;
	currentSlide: number;
	updatedAdGroupSplit: DataInterface<IGCBAdGroupSplitReq>;
	activeAdData: DataInterface<IGCBAds>;
	activeAdAction: DataInterface<IGCBActiveAdAction>;
	activeAdgroupData: DataInterface<IGCBAdgroup>;
	activeAdFormArrayData: DataInterface<
		[{ headlineForm?: FormArray; update?: boolean }, { descriptionForm?: FormArray; update?: boolean }, { urlForm?: FormControl; update?: boolean }]
	>;
	allAdgroupData: DataInterface<IGCBAdgroup[]>;
	tempAdgroupData: DataInterface<IGCBAdgroup[]>;
	activeSmartCreateIndex: DataInterface<number>;
	adPreview: any;
	adExtensionPreview: GcbStatePreviewInterface;
	activeExtensionData: DataInterface<{ site: IGCBAdExtensionSiteResponse; callout: IGCBAdExtensionCalloutResponse; call: IGCBAdExtensionCallResponse }>;
	updateExtensionData: DataInterface<{ site?: FormArray; callout?: FormArray; call?: FormGroup }>;
	activeAdStrengthData: DataInterface<IGCBAdStrengthResponse>;
	adgroupsExhaustedData: DataInterface<{ exhausted: boolean; breakpoint: boolean; totalAdgroups: number }>;
	metricForecastData: DataInterface<IGCBMetricForecastResponse>;
	metricForecastAdgroupsUsedData: DataInterface<IGCBMetricForecastAdroupsUsedResponse>;
	dexterAssist: DataInterface<boolean>;
}

export const initialState: State = {
	loading: false,
	gcbSession: null,
	updatedCreationOption: null,
	updatedCampaignObjective: null,
	updatedCampaignType: null,
	updateKeywords: null,
	updateCatzdKeywords: null,
	currentSlide: 1,
	updatedAdGroupSplit: null,
	activeAdData: null,
	activeAdAction: { isLoaded: true, errorCode: null, data: { activeSubStep: 0, activeType: null, index: null } },
	activeAdgroupData: null,
	activeAdFormArrayData: null,
	allAdgroupData: null,
	tempAdgroupData: null,
	activeSmartCreateIndex: null,
	adPreview: null,
	adExtensionPreview: null,
	activeExtensionData: null,
	updateExtensionData: null,
	activeAdStrengthData: null,
	adgroupsExhaustedData: null,
	metricForecastData: null,
	metricForecastAdgroupsUsedData: null,
	dexterAssist: null
};
export const reducer = createReducer(
	initialState,
	on(SharedActions.clearShareState, state => ({ ...state, ...initialState })),
	on(SharedActions.loadShareds, state => state),
	on(SharedActions.loadSharedsSuccess, (state, action) => state),
	on(SharedActions.loadSharedsFailure, (state, action) => state),
	on(SharedActions.updateCurrentStep, (state, action) => ({ ...state, currentSlide: action.step })),
	on(SharedActions.loadSession, (state, action) => {
		return {
			...state,
			gcbSession: {
				...state.gcbSession,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.loadSessionSuccess, (state, action) => {
		return {
			...state,
			gcbSession: {
				...state.gcbSession,
				data: action.data
			}
		};
	}),
	on(SharedActions.loadSessionFailure, (state, action) => {
		return {
			...state,
			gcbSession: {
				...state.gcbSession,
				errorCode: action.error
			}
		};
	}),

	on(SharedActions.updateCreationOption, (state, action) => {
		return {
			...state,
			updatedCreationOption: {
				...state.updatedCreationOption,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.updateCreationOptionSuccess, (state, action) => {
		return {
			...state,
			updatedCreationOption: {
				...state.updatedCreationOption,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateCreationOptionFailure, (state, action) => {
		return {
			...state,
			updatedCreationOption: {
				...state.updatedCreationOption,
				errorCode: action.error
			}
		};
	}),

	on(SharedActions.updateCampaignObj, (state, action) => {
		return {
			...state,
			updatedCampaignObjective: {
				...state.updatedCampaignObjective,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.updateCampaignObjSuccess, (state, action) => {
		return {
			...state,
			updatedCampaignObjective: {
				...state.updatedCampaignObjective,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateCampaignObjFailure, (state, action) => {
		return {
			...state,
			updatedCampaignObjective: {
				...state.updatedCampaignObjective,
				errorCode: action.error
			}
		};
	}),

	on(SharedActions.updateCampaignType, (state, action) => {
		return {
			...state,
			updatedCampaignType: {
				...state.updatedCampaignType,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.updateCampaignTypeSuccess, (state, action) => {
		return {
			...state,
			updatedCampaignType: {
				...state.updatedCampaignType,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateCampaignTypeFailure, (state, action) => {
		return {
			...state,
			updatedCampaignType: {
				...state.updatedCampaignType,
				errorCode: action.error
			}
		};
	}),
	on(SharedActions.updateKeywords, (state, action) => {
		return {
			...state,
			updateKeywords: {
				...state.updateKeywords,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.updateKeywordsSuccess, (state, action) => {
		return {
			...state,
			updateKeywords: {
				...state.updateKeywords,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateKeywordsFailure, (state, action) => {
		return {
			...state,
			updateKeywords: {
				...state.updateKeywords,
				errorCode: action.error
			}
		};
	}),
	on(SharedActions.updateCatzdKeywords, (state, action) => {
		return {
			...state,
			updateCatzdKeywords: {
				...state.updateCatzdKeywords,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.updateCatzdKeywordsSuccess, (state, action) => {
		return {
			...state,
			updateCatzdKeywords: {
				...state.updateCatzdKeywords,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateCatzdKeywordsFailure, (state, action) => {
		return {
			...state,
			updateCatzdKeywords: {
				...state.updateCatzdKeywords,
				errorCode: action.error
			}
		};
	}),

	on(SharedActions.updateAdGroupSplit, (state, action) => {
		return {
			...state,
			updatedAdGroupSplit: {
				...state.updatedAdGroupSplit,
				isLoaded: true
			}
		};
	}),
	on(SharedActions.updateAdGroupSplitSuccess, (state, action) => {
		return {
			...state,
			updatedAdGroupSplit: {
				...state.updatedAdGroupSplit,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateActiveAdData, (state, action) => {
		return {
			...state,
			activeAdData: {
				...state.activeAdData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateActiveAdAction, (state, action) => {
		return {
			...state,
			activeAdAction: {
				...state.activeAdAction,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateActiveAdGroupData, (state, action) => {
		return {
			...state,
			activeAdgroupData: {
				...state.activeAdgroupData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateAdFormArrayData, (state, action) => {
		return {
			...state,
			activeAdFormArrayData: {
				...state.activeAdFormArrayData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateAllAdGroupData, (state, action) => {
		return {
			...state,
			allAdgroupData: {
				...state.allAdgroupData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateTempAdGroupData, (state, action) => {
		return {
			...state,
			allAdgroupData: {
				...state.tempAdgroupData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateActiveSmartCreateIndex, (state, action) => {
		return {
			...state,
			activeSmartCreateIndex: {
				...state.activeSmartCreateIndex,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateActiveExtensionData, (state, action) => {
		return {
			...state,
			activeExtensionData: {
				...state.activeExtensionData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateExtensionData, (state, action) => {
		return {
			...state,
			updateExtensionData: {
				...state.updateExtensionData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateAdPreviews, (state, action) => ({ ...state, adPreview: action.data })),
	on(SharedActions.updateAdExtensionPreviews, (state, action) => ({ ...state, adExtensionPreview: action.data })),
	on(SharedActions.updateActiveAdStrengthData, (state, action) => {
		return {
			...state,
			activeAdStrengthData: {
				...state.activeAdStrengthData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateAdgroupsExhausted, (state, action) => {
		return {
			...state,
			adgroupsExhaustedData: {
				...state.adgroupsExhaustedData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateMetricForecastData, (state, action) => {
		return {
			...state,
			metricForecastData: {
				...state.metricForecastData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateMetricForecastAdroupsUsedData, (state, action) => {
		return {
			...state,
			metricForecastAdgroupsUsedData: {
				...state.metricForecastAdgroupsUsedData,
				isLoaded: true,
				data: action.data
			}
		};
	}),
	on(SharedActions.updateDexterAssist, (state, action) => {
		return {
			...state,
			dexterAssist: {
				...state.dexterAssist,
				isLoaded: true,
				data: action.data
			}
		};
	})
);
