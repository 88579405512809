export enum GcbSlideStepEnum {
	CreationProcess = 0,
	ObjectiveSelection = 1,
	CampaignType = 2,
	ConversionAction = 3,
	AboutGcb = 4,
	AboutProgressGcb = 5,
	KeywordGCB = 6,
	AdGroupType = 7,
	SmartAdSelection = 8,
	KeywordSaveProgress = 9,
	ConfigureAdGroup = 10,
	AdExtensionChoice = 11,
	Extensions = 12,
	AppReview = 13,
	SelectedKeywords = 14,
	ViewAllKeywords = 15,
	ViewKeyword = 16
}
