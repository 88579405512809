<div class="container">
	<div *ngIf="!submitting" class="main-wrapper">
		<div class="left-dock">
			<div class="logo">
				<img src="assets/logo/lolly-logo.svg" />
			</div>
			<div class="plan-text">{{ planDetails?.subscriptionName }}{{ ' plan | Per' }} {{ planDetails?.billingInterval }}</div>
			<div *ngIf="!planDetails?.isFreeTrial" class="amount-text">
				{{ getPlanAmount }}
			</div>
			<div *ngIf="planDetails?.isFreeTrial" class="amount-text">
				{{ getCurrentlyChargingAmount }}
			</div>
			<div *ngIf="!planDetails?.isFreeTrial" class="topay-text">{{ 'To pay now, ' }}{{ getCurrentlyChargingAmount }}</div>
			<div *ngIf="!planDetails?.isFreeTrial" class="topay-details-text">
				{{ 'This payment is to cover from ' }} {{ (planDetails?.subscriptionStartDate | date: 'dd/MM/yyyy').replaceAll('/', '.') }}
				{{ ' to the last date of this month.' }}
			</div>
			<div *ngIf="!planDetails?.isFreeTrial" class="next-bill-text">
				{{ 'Your next bill will be ' }}{{ getPlanAmount + ' on' }}{{ ' ' + planDetails?.nextInvoiceDate }}
			</div>
			<div *ngIf="planDetails?.isFreeTrial" class="next-bill-text">
				Your free trial of {{ ' ' + planDetails?.freeTrialDuration + ' ' }} days will end on {{ ' ' + planDetails?.nextInvoiceDate + '. ' }}Your next
				bill will be {{ ' ' + getFreeTrialCurrentlyChargingAmount + ' on' }}{{ ' ' + planDetails?.nextInvoiceDate }}
			</div>
			<div class="next-bill-details-text">
				{{ 'We will bill you ' }} {{ getPlanAmount + ' ' }} {{ 'on the 1st of every month, unless you cancel your subscription.' }}
			</div>
		</div>
		<div class="right-dock">
			<div class="wrapper">
				<div class="payment-text">
					{{ 'Enter Payment Details' }}
				</div>
				<div class="payment-type-radio">
					<mat-radio-group (change)="cardTypeChanged($event)" aria-label="Select an option">
						<mat-radio-button [checked]="true" value="1">
							<div class="cards-option">
								<img src="assets/new-signup/visa.svg" />
								<img src="assets/new-signup/mastercard.svg" />
								<img src="assets/new-signup/amex.svg" />
							</div>
						</mat-radio-button>
						<mat-radio-button class="no-right-margin" value="2">
							<div class="cards-option"><img src="assets/new-signup/paypal.svg" /></div>
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div *ngIf="paymentWallType === 'BlueSnap'" class="full-text-input full-no-bottom">
					<div class="label">{{ 'Card Information' }}</div>
					<!-- <app-input-field
						class="title-input"
						[placeHolderTextBox]="'1234 1234 1234 1234'"
						[inputControl]="bnccNoFormControl"
						[errorMessage]="''"
						[showValidIcon]="true"
						[hideError]="true"
						[showCustomIcon]="'assets/icons/cards.png'"
						[iconWidth]="'120px'"
						[marginRight]="'6px'"
					></app-input-field> -->
					<div class="ccn">
						<div class="form-control" [control]="bnccNoFormControl" id="card-number" data-bluesnap="ccn"></div>
						<div id="card-logo" class="input-group-addon">
							<img alt="" src="assets/icons/cards.png" height="20px" />
						</div>
					</div>
				</div>
				<div *ngIf="paymentWallType === 'BlueSnap'" class="text-input-split text-split-joined">
					<div class="left">
						<!-- <app-input-field
							[showValidIcon]="true"
							[hideError]="true"
							class="title-input"
							[placeHolderTextBox]="'MM/YY'"
							[inputControl]="expiryDateFormControl"
						></app-input-field> -->
						<div class="exp" [control]="expiryDateFormControl" id="exp-date" data-bluesnap="exp"></div>
					</div>
					<div class="right">
						<!-- <app-input-field
							[showValidIcon]="true"
							[hideError]="true"
							class="title-input"
							[placeHolderTextBox]="'CVC'"
							[inputControl]="cvcFormControl"
							[showCustomIcon]="'assets/icons/tooltip.svg'"
							[iconWidth]="'15px'"
							[marginRight]="'12px'"
						></app-input-field> -->
						<div class="cvv" [control]="cvcFormControl" data-bluesnap="cvv"></div>
					</div>
				</div>

				<div *ngIf="paymentWallType === 'PayPal'" class="full-text-input">
					<div class="label">{{ 'Paypal email address' }}</div>
					<app-input-field
						class="title-input"
						[placeHolderTextBox]="'Paypal email address'"
						[inputControl]="emailFormControl"
						[showValidIcon]="true"
						[hideError]="false"
						[errorMessage]="'Enter a valid email'"
					></app-input-field>
				</div>

				<div *ngIf="paymentWallType === 'BlueSnap'" class="full-text-input l-margin">
					<div class="label">{{ 'Name on card' }}</div>
					<app-input-field
						[showValidIcon]="true"
						[hideError]="false"
						class="title-input"
						[placeHolderTextBox]="'Name on card'"
						[inputControl]="nameFormControl"
						[errorMessage]="'Enter a valid name'"
					></app-input-field>
				</div>

				<div class="label" style="margin-top: 18px; font-size: 14px; color: #5e5e5e;">
					{{ 'Billing Address' }}
				</div>

				<div class="text-input-split">
					<div class="left">
						<app-input-field
							[showValidIcon]="true"
							[hideError]="false"
							class="title-input"
							[placeHolderTextBox]="'Address'"
							[inputControl]="billingFormControl"
							[errorMessage]="'Enter a valid address'"
						></app-input-field>
					</div>
					<div class="right">
						<div class="label">{{ '' }}</div>
						<app-input-field
							[showValidIcon]="true"
							[hideError]="false"
							class="title-input"
							[placeHolderTextBox]="'City'"
							[inputControl]="cityFormControl"
							[errorMessage]="'Enter a valid city'"
						></app-input-field>
					</div>
				</div>

				<div class="text-input-split l-margin">
					<div class="left">
						<app-custom-drpdown-op1
							[placeHolder]="'Select your country'"
							[data]="countryData"
							[dropdownFormControl]="countryControlField"
							[hideClearButton]="true"
							[searchEnable]="true"
							[errorMessage]="'Select a country'"
						></app-custom-drpdown-op1>
						<!-- <app-input-field class="title-input" [placeHolderTextBox]="'Select Country'" [inputControl]="expiryDateFormControl"></app-input-field> -->
					</div>
					<div class="right">
						<app-input-field
							[showValidIcon]="true"
							[hideError]="false"
							class="title-input"
							[placeHolderTextBox]="'Zipcode'"
							[inputControl]="zipFormControl"
							[errorMessage]="'Enter a valid zip code'"
						></app-input-field>
					</div>
				</div>

				<div (click)="continueSignup()" [ngClass]="{ 'invalid-continue': !formFieldsValid() }" class="continue-btn-wrapper">
					<div class="btn">
						<div class="items">
							<div class="text">{{ !planDetails?.isFreeTrial ? 'Pay' : 'Continue' }}</div>
						</div>
					</div>
				</div>

				<div class="bottom-text-action">
					<div class="dark">
						{{
							'By confirming your subscription, you allow Sansa to charge your card for this payment and future payments in accordance
							with their '
						}}
						<a href="https://www.lolly.com/terms/" target="_blank">{{ 'terms.' }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="submitting" class="main-wrapper">
		<lolly-spinning-loader></lolly-spinning-loader>
	</div>
</div>

<app-toast-notification></app-toast-notification>
