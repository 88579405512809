<div [ngClass]="{ 'relative-container': fromGoogleCB || fromFacebookCB }" #menuDD1 class="mc-main-container">
	<!-- Initial State when unselected -->
	<ng-container>
		<div #menuDD2 class="mc-top-card" (click)="openDDSelection()">
			<div #menuDD3 class="mc-name-img">
				<img style="width: 20px; height: auto;" *ngIf="!fromGoogleCB" #menuDD4 class="mc-channel-img" [src]="'assets/icons/Icon-facebook.svg'" />
				<img style="width: 20px; height: auto;" *ngIf="fromGoogleCB" #menuDD4 class="mc-channel-img" [src]="'assets/icons/icon-google-g.svg'" />
				<!--For Multi Channel Support-->
				<!-- <img [ngStyle]="openDD ? { display: 'none' } : { 'color': '#707070' } " class="mc-channel-img"
								[src]="'assets/icons/Icon-facebook.svg'" /> -->
				<!-- <div [ngStyle]="openDD ? { 'margin-left': '0px' } : { 'color': '#707070' } " *ngIf="openDD" class="mc-top-card-font">{{
													'Accounts Selection' }}
												</div>
												<div *ngIf="!openDD" class="mc-top-card-font">{{ userSelection }}</div> -->
				<div *ngIf="!fromGoogleCB" #menuDD5 class="mc-top-card-font">{{ userSelection }}</div>
				<div *ngIf="fromGoogleCB" #menuDD5 class="mc-top-card-font">{{ selectedGoogleAccountName }}</div>
			</div>
			<img [ngClass]="{ 'flip-mc-dd-img': openDD ? true : false }" #menuDD6 class="mc-dd-img" [src]="'assets/icons/main/arrow-dropdown.svg'" />
		</div>
	</ng-container>
	<ng-container *ngIf="openDD">
		<div #menuDD7 class="mc-bottom-card">
			<div [ngClass]="{ 'mc-search-hide': fromGoogleCB || fromFacebookCB }" class="mc-search">
				<mat-icon>search</mat-icon>
				<mat-form-field floatLabel="never">
					<mat-label *ngIf="!adAccountSearchFormControl.value">{{ 'SEARCH_FOR_ACCOUNT_NAME' | translate }}...</mat-label>
					<input
						#searchInputRef
						(keydown)="searchForAccount($event)"
						[formControl]="adAccountSearchFormControl"
						class="placeholder-wrapper"
						matInput
						value=""
					/>
				</mat-form-field>
			</div>
			<div #menuDD8 [ngClass]="{ 'mc-top-label-hide': fromGoogleCB || fromFacebookCB }" class="mc-top-label">
				<div #menuDD9 class="mc-channel-title">{{ 'Channel' }}</div>
				<div #menuDD10 class="mc-accounts-title">{{ 'Your accounts' }}</div>
			</div>
			<ng-container *ngIf="childSelectionStatus === false">
				<div #menuDD11 *ngFor="let channelAccountGroups of channelGroups; let i = index">
					<div
						*ngIf="channelAccountGroups.channelName === selectedAdToDisplay || selectedAdToDisplay === 'allAccounts'"
						class="mc-channel-account-group"
					>
						<div #menuDD12 class="mc-account-group" *ngFor="let cgAccounts of channelAccountGroups.data; let j = index">
							<div #menuDD13 (click)="parentAccountAction(cgAccounts)" class="inner-parent">
								<div #menuDD14 class="mc-channel">
									<img *ngIf="j === 0" class="mc-channel-img" (click)="backTodropDown()" [src]="channelAccountGroups.channelIcon" />
								</div>
								<div #menuDD15 class="mc-accounts">
									<div #menuDD16 class="mc-account-circle">
										<div #menuDD17 class="mc-circle"></div>
										<div #menuDD18 class="mc-account-font">
											{{ cgAccounts.name }}
										</div>
									</div>
									<img
										[ngClass]="{ 'flip-mc-dd-img': cgAccounts.isOpen ? true : false }"
										#menuDD21
										class="mc-dd-img"
										[src]="'assets/icons/main/arrow-dropdown.svg'"
									/>
								</div>
							</div>
							<ng-container *ngIf="cgAccounts.isOpen === true">
								<div #menuDD19 class="mc-account-group-children" *ngFor="let accounts of cgAccounts.children">
									<div #menuDD20 (click)="childAccountAction(accounts, channelAccountGroups.channelName)" class="inner-children">
										<div #menuDD23 class="mc-channel"></div>
										<div #menuDD24 class="mc-accounts">
											<div #menuDD25 class="mc-account-circle">
												<div #menuDD26 class="mc-circle"></div>
												<div #menuDD27 class="mc-account-font">
													{{ accounts.name }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="childSelectionStatus === true">
				<div #menuDD11b *ngFor="let channelAccountGroups of channelGroups; let i = index" class="mc-channel-account-group">
					<div #menuDD12b class="mc-account-group">
						<div #menuDD13b (click)="expandAllGroups(channelAccountGroups.channelName)" class="inner-parent center">
							<div #menuDD14b class="mc-channel"><img class="mc-channel-img" [src]="channelAccountGroups.channelIcon" /></div>
							<div #menuDD15b class="mc-accounts">
								<div #menuDD16b class="mc-account-circle">
									<div #menuDD17b class="mc-circle"></div>
									<div #menuDD18b class="mc-account-font" *ngIf="channelAccountGroups.channelName === 'facebook'">
										{{ userSelection }}
									</div>
									<div #menuDD18b class="mc-account-font" *ngIf="channelAccountGroups.channelName === 'google'">
										{{ selectedGoogleAccountName }}
									</div>
								</div>
								<img #menuDD22 class="mc-dd-img" [src]="'assets/icons/main/arrow-dropdown.svg'" />
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>
