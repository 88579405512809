import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { IFCBSessionCreateResponse } from '../../models/fb-session-create.interface';
import { AdsetStage1FormValueInterface, AdsetStage1ObjReq } from '../../models/form-value.interface';

export const loadSession = createAction('[SharedFCB] Load Session');
export const loadSessionSuccess = createAction('[SharedFCB] Load Session Success', props<{ data: IFCBSessionCreateResponse }>());
export const loadSessionFailure = createAction('[SharedFCB] Load Session Failure', props<{ error: any }>());

export const updateAdsetStage1Obj = createAction('[SharedFCB] Update Adset Objective', props<{ data: AdsetStage1ObjReq }>());
export const updateAdsetStage1Success = createAction('[SharedFCB] Update Adset Objective Success', props<{ data: AdsetStage1ObjReq }>());
export const updateAdsetStage1Failure = createAction('[SharedFCB] Update Adset Objective Failure', props<{ error: any }>());

export const updateCurrentStep = createAction('[SharedFCB state] update current step', props<{ step: number }>());

export const updateAdFormArrayData = createAction(
	'[SharedFCB] Update Active Form Array Data',
	props<{
		data: [{ headlineForm?: FormArray; update?: boolean }, { descriptionForm?: FormArray; update?: boolean }, { urlForm?: FormControl; update?: boolean }];
	}>()
);
