import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFbSideStepper, ISideStepper } from './side-stepper.interface';
import { Store } from '@ngrx/store';
import { GcbSlideStepEnum } from 'src/app/google-campaign-builder/enums/gcb-slide-step-enum.enum';
import { selectCurrentSlide } from 'src/app/google-campaign-builder/states/shared/shared.selectors';
import { FcbSlideStepEnum } from 'src/app/facebook-campaign-builder/enums/fcb-slide-step-enum.enum';
import { selectFbCurrentSlide } from 'src/app/facebook-campaign-builder/states/shared/shared.selectors';

@Component({
	selector: 'side-stepper',
	templateUrl: './side-stepper.component.html',
	styleUrls: ['./side-stepper.component.scss']
})
export class SideStepperComponent implements OnInit {
	@Input() public steps: ISideStepper[] | IFbSideStepper[] = [];
	@Output() public stepClick: EventEmitter<ISideStepper | IFbSideStepper> = new EventEmitter();
	@Input() public channel: string = 'google';
	public currentStep = null;
	public activeStep = null;
	constructor(private store: Store) {}

	public ngOnInit(): void {
		if (this.channel === 'facebook') {
			this.facebookSelected();
		} else {
			this.googleSelected();
		}
	}

	public facebookSelected(): void {
		this.store.select(selectFbCurrentSlide).subscribe(res => {
			this.currentStep = res;
			switch (this.currentStep) {
				case FcbSlideStepEnum.Campaign:
					this.activeStep = FcbSlideStepEnum.Campaign;
					return;
				case FcbSlideStepEnum.CampaignName:
					this.activeStep = FcbSlideStepEnum.CampaignName;
					return;
				// case GcbSlideStepEnum.KeywordGCB:
				// case GcbSlideStepEnum.ViewAllKeywords:
				// case GcbSlideStepEnum.SelectedKeywords:
				// case GcbSlideStepEnum.AboutProgressGcb:
				// case GcbSlideStepEnum.ViewKeyword:
				// 	this.activeStep = GcbSlideStepEnum.KeywordGCB;
				// 	return;
				// case GcbSlideStepEnum.KeywordSaveProgress:
				// case GcbSlideStepEnum.AdGroupType:
				// case GcbSlideStepEnum.SmartAdSelection:
				// 	this.activeStep = GcbSlideStepEnum.AdGroupType;
				// 	return;
				default:
					this.activeStep = this.currentStep;
					return;
			}
		});
	}

	public googleSelected(): void {
		this.store.select(selectCurrentSlide).subscribe(res => {
			this.currentStep = res;
			switch (this.currentStep) {
				case GcbSlideStepEnum.AboutGcb:
					this.activeStep = GcbSlideStepEnum.AboutGcb;
					return;
				case GcbSlideStepEnum.KeywordGCB:
				case GcbSlideStepEnum.ViewAllKeywords:
				case GcbSlideStepEnum.SelectedKeywords:
				case GcbSlideStepEnum.AboutProgressGcb:
				case GcbSlideStepEnum.ViewKeyword:
					this.activeStep = GcbSlideStepEnum.KeywordGCB;
					return;
				case GcbSlideStepEnum.KeywordSaveProgress:
				case GcbSlideStepEnum.AdGroupType:
				case GcbSlideStepEnum.SmartAdSelection:
					this.activeStep = GcbSlideStepEnum.AdGroupType;
					return;
				default:
					this.activeStep = this.currentStep;
					return;
			}
		});
	}
}
