import { DropdownColumnInterface } from '../../../live-search-dropdown/dropdown-columns.interface';

export class TargetingDropdownConfig {
	public static locationColumns: DropdownColumnInterface[] = [
		{
			property: 'name',
			showTooltip: true,
			primaryColumn: true
		},
		{
			property: 'type',
			showTooltip: false,
			formatText: true
		},
		{
			property: 'region',
			showTooltip: true
		},
		{
			property: 'cityOrCountryName',
			showTooltip: true
		},
		{
			property: 'selectedLocationString',
			showTooltip: false,
			showInSelectContainer: true,
			excludeColumnFromDropdown: true
		}
	];
	public static languagesColumns: DropdownColumnInterface[] = [
		{
			property: 'name',
			showInSelectContainer: true
		}
	];
	public static interestColumns: DropdownColumnInterface[] = [
		{
			property: 'name',
			showInSelectContainer: true
		},
		{
			property: 'viewFormattedPath',
			showTooltip: true
		}
	];
	public static audienceColumns: DropdownColumnInterface[] = [
		{
			property: 'name',
			showInSelectContainer: true,
			primaryColumn: true
		},
		{
			property: 'size'
		}
	];
	public static customAudienceColumns: DropdownColumnInterface[] = [
		{
			property: 'name',
			showInSelectContainer: true,
			showTooltip: true,
			primaryColumn: true
		},
		{
			property: 'size'
		}
	];
	public static gcbLocationColumns: DropdownColumnInterface[] = [
		{
			property: 'name',
			primaryColumn: true
		},
		{
			property: 'canonicalName',
			formatText: true
		},
		{
			property: 'countryCode',
			formatText: true
		}
	];
	public static gcbLocationColumnsTwo: DropdownColumnInterface[] = [
		{
			property: 'canonicalName',
			primaryColumn: true,
			formatText: true
		}
	];
}
