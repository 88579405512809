<nav style="display: none;" mat-tab-nav-bar class="navigation">
	<div class="left-menu"></div>
</nav>
<div class="wrapper">
	<div style="display: none;" [ngClass]="{ 'margin-channel-select': !this.expandMenu }" class="channel-select">
		<app-multi-channel-account-selection></app-multi-channel-account-selection>
	</div>
	<div class="full">
		<div class="container">
			<div [ngClass]="{ 'top-title-stretch': false }" class="top-title">
				<div class="f18-black-medium">
					{{ 'Select card below to Create your campaign on ' + linkToDisplay + '?' }}
				</div>
				<div style="display: none;" class="f12-black">{{ 'Click and select a channel that you like to start your campaign
					creation.' }}</div>
			</div>

			<div class="cards">
				<div
					[ngClass]="{ 'channel-inactive': !channelsContent[0].isActive, 'channel-disabled': !channelsContent[0].enabled }"
					*ngIf="linkToDisplay === 'facebook'"
					class="channel-card"
					(click)="chooseChannel(channelsContent[0])"
				>
					<div class="card-top">
						<span style="display: flex;">
							<h4>{{ channelsContent[0].title }}</h4>
							<!-- <div *ngIf="channelsContent[0].name === 'facebook'" class="beta">COMING SOON</div>
							<div *ngIf="channelsContent[1].name === 'google'" class="beta">NEW</div> -->
						</span>
						<div class="center-img">
							<img *ngIf="channelsContent[0].isActive" [src]="channelsContent[0].url" />
							<img *ngIf="!channelsContent[1].isActive" [src]="channelsContent[1].url" />
						</div>
					</div>
					<div class="card-bottom">
						<div class="selected-circle"></div>
						<!-- <div *ngIf="channelsContent[0].name === 'facebook'" class="label">{{ selectedFBAccount }}</div> -->
						<div *ngIf="channelsContent[1].name === 'google' && linkToDisplay === 'google'" class="label">{{ googleAccountName }}</div>
					</div>
				</div>
				<div
					[ngClass]="{ 'channel-inactive': !channelsContent[1].isActive, 'channel-disabled': !channelsContent[1].enabled }"
					*ngIf="linkToDisplay === 'google'"
					class="channel-card"
					(click)="chooseChannel(channelsContent[1])"
				>
					<div class="card-top">
						<span style="display: flex;">
							<h4>{{ channelsContent[1].title }}</h4>
							<div *ngIf="channelsContent[0].name === 'google'" class="beta">NEW</div>
							<div *ngIf="channelsContent[1].name === 'facebook'" class="beta">COMING SOON</div>
						</span>
						<div class="center-img">
							<img *ngIf="channelsContent[1].isActive" [src]="channelsContent[1].url" />
							<img *ngIf="!channelsContent[0].isActive" [src]="channelsContent[0].url" />
						</div>
					</div>
					<!--<div class="card-bottom">
											 <div class="selected-circle"></div>
						<div *ngIf="channelsContent[1].name === 'facebook'" class="label">{{ selectedFBAccount }}</div>
						<div *ngIf="channelsContent[1].name === 'google'" class="label">{{ googleAccountName }}</div>
						</div> -->
				</div>
			</div>
			<div class="dropDown">
				<app-multi-channel-account-selection
					[fromGoogleCB]="true"
					[fromFacebookCB]="false"
					*ngIf="linkToDisplay === 'google'"
				></app-multi-channel-account-selection>
				<app-multi-channel-account-selection
					[fromFacebookCB]="true"
					[fromGoogleCB]="false"
					*ngIf="linkToDisplay === 'facebook'"
				></app-multi-channel-account-selection>
			</div>
		</div>
	</div>
</div>
