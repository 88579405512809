<div class="mega-wrapper" [class.wrapp-no-rec]="recommendationsList && !recommendationsList.length" *ngIf="showRecommendations">
	<div class="recommendations-header">
		<div class="left-recommendations-header">
			<span class="recommendations-title">
				<img src="assets/icons/filed-icon.svg" />
				Sansa recommendations

				<span class="hide-recommendations" (click)="changeRecommendationsToggle()">
					<mat-icon>arrow_forward_ios</mat-icon>
					<mat-icon>arrow_forward_ios</mat-icon>
				</span>
			</span>
		</div>
	</div>

	<div class="carousel-container">
		<ngx-slick-carousel
			class="carousel"
			#slickModal="slick-carousel"
			[config]="slideConfig"
			(afterChange)="afterChange($event)"
			*ngIf="recommendationsList"
		>
			<div ngxSlickItem class="slide" *ngFor="let recommendation of recommendationsList; index as i">
				<div class="item-meta">
					<div class="meta-list">
						<img *ngIf="recommendation.level === 'ad'" src="assets/icons/optimize/tv.svg" />
						<img *ngIf="recommendation.level === 'adset'" src="assets/icons/optimize/visibility-adset.svg" />
						<img *ngIf="recommendation.level === 'campaign'" src="assets/icons/optimize/megaphone.svg" />
						<span>{{ recommendation.structureName }}</span>
					</div>
					<div class="meta-list">
						<img src="assets/icons/optimize/metric-icon.svg" />
						<span>{{ recommendation.metrics[0].displayName }}</span>
					</div>
				</div>
				<div class="item-title">{{ recommendation.title }}</div>
				<div class="item-content">
					<div class="context">
						{{ recommendation.subText }}
					</div>
					<div class="btns-item">
						<btn01 *ngIf="recommendation.isApplicable" (click)="onApplyClick(recommendation.recommendationId, i)">Apply</btn01>
					</div>
				</div>
			</div>
		</ngx-slick-carousel>

		<ngx-slick-carousel
			class="carousel"
			#slickModal="slick-carousel"
			[config]="slideConfig"
			(afterChange)="afterChange($event)"
			*ngIf="recommendationsList && !recommendationsList.length"
		>
			<div ngxSlickItem class="slide">
				<div class="no-recommendations">
					<h3>
						<mat-icon>check_circle</mat-icon>
						No Recommendations
					</h3>
					<p>There are no new recommendations detected. Recommendations will be showed once you change or create new campaigns.</p>
				</div>
			</div>
		</ngx-slick-carousel>
		<div class="arrows-recommendations" *ngIf="recommendationsList && recommendationsList.length">
			<div class="recommendations-arrow-left arrow"><mat-icon>keyboard_arrow_left</mat-icon></div>
			{{ currentSlide }}/{{ recommendationsList && recommendationsList.length ? recommendationsList.length : 1 }}
			<div class="recommendations-arrow-right arrow"><mat-icon>keyboard_arrow_right</mat-icon></div>
		</div>
	</div>
</div>

<div class="loading" *ngIf="loading">
	<lolly-spinning-loader></lolly-spinning-loader>
</div>
