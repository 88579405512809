<div class="landing-page">
	<div class="lp-first-row">
		<img class="landing-image" src="assets/icons/onboarding/landing-page-img.svg" alt="landing page" />
		<section class="lp-text-section">
			<img src="assets/logo/filed-f-circle-logo.svg" alt="logo" />
			<h1>Welcome.</h1>
			<h1>Get started with your first campaign...</h1>
		</section>
	</div>

	<div class="lp-second-row">
		<div class="lp-card">
			<mat-icon class="blue-icon">offline_bolt</mat-icon>
			<h6 class="lp-card-title">OPTIMIZE</h6>
			<h3>Go and see how Sansa can improve your campaigns</h3>
			<app-button
				(buttonClicked)="onOptimizeClick()"
				[buttonClass]="mainBtn.Tertiary"
				[buttonType]="typeBtn.Simple"
				[buttonLabel]="'START VIEWING RECOMMENDATIONS'"
			></app-button>
		</div>
		<div class="lp-card">
			<mat-icon class="blue-icon">dvr</mat-icon>
			<h6 class="lp-card-title">CAMPAIGN BUILDER</h6>
			<h3>Would you like to start building a new campaign?</h3>
			<app-button
				(buttonClicked)="onCampaignBuilderCreateClick()"
				[buttonClass]="mainBtn.Tertiary"
				[buttonType]="typeBtn.Simple"
				[buttonLabel]="'CREATE NEW'"
			></app-button>
		</div>
		<div class="lp-card">
			<mat-icon class="blue-icon">donut_large</mat-icon>
			<h6 class="lp-card-title">REPORTS</h6>
			<h3>Would you like to start building a new report?</h3>
			<app-button
				(buttonClicked)="onReportsCreateClick()"
				[buttonClass]="mainBtn.Tertiary"
				[buttonType]="typeBtn.Simple"
				[buttonLabel]="'CREATE NEW'"
			></app-button>
		</div>
	</div>
</div>
