import { createReducer, on } from '@ngrx/store';
import * as KeywordActions from './keyword.actions';
import { KeywordTypeEnum } from '../../model/gcb-catzd-keywords.interface';
import { LoadedKeywordViewInterface } from '../../model/keyword-view-interface';
import { clearBudgetAndForecast, updateTempSelectedKeywords } from './keyword.actions';

export const keywordFeatureKey = 'keyword';

export interface State {
	selectedAssist: boolean;
	isSelectAll: boolean;
	selectedKeywordType: KeywordTypeEnum;
	tempSelectedKeywords: any[];
	selectedKeywords: any[];
	loadedAssistKeywords: LoadedKeywordViewInterface[];
	loadedNonAssistKeywords: LoadedKeywordViewInterface[];
	budgetValue: number;
	budgetUtilized: number;
	selectedKeywordCount: { allKeywordCount: number; selectedKeywordCount: number };
	keywordForecast: { estimatedClicks: number; estimatedConversions: number[] };
}

export const initialState: State = {
	selectedAssist: true,
	isSelectAll: false,
	selectedKeywordType: null,
	tempSelectedKeywords: [],
	selectedKeywords: [],
	loadedAssistKeywords: null,
	loadedNonAssistKeywords: null,
	budgetValue: 0,
	budgetUtilized: 0,
	selectedKeywordCount: { allKeywordCount: 0, selectedKeywordCount: 0 },
	keywordForecast: { estimatedClicks: 0, estimatedConversions: null }
};

export const reducer = createReducer(
	initialState,
	on(KeywordActions.clearKeywordState, state => ({ ...state, ...initialState })),
	on(KeywordActions.loadKeywordViewAssistSuccess, (state, action) => ({ ...state, loadedAssistKeywords: action.data })),
	on(KeywordActions.loadKeywordViewNonAssistSuccess, (state, action) => ({ ...state, loadedNonAssistKeywords: action.data })),
	on(KeywordActions.updateViewKeywordType, (state, action) => ({ ...state, selectedKeywordType: action.keywordType })),
	on(KeywordActions.updateViewKeywordAssist, (state, action) => ({ ...state, selectedAssist: action.assist })),
	on(KeywordActions.updateSelectedKeywords, (state, action) => ({ ...state, selectedKeywords: action.keywords })),
	on(KeywordActions.updateTempSelectedKeywords, (state, action) => ({ ...state, tempSelectedKeywords: action.keywords })),
	on(KeywordActions.updateSelectAllKeywords, (state, action) => ({ ...state, isSelectAll: action.isSelectAll })),
	on(KeywordActions.updateBudgetValue, (state, action) => ({ ...state, budgetValue: action.budget })),
	on(KeywordActions.updateSelectedKeywordCounts, (state, action) => ({
		...state,
		selectedKeywordCount: { allKeywordCount: action.totalKeywords, selectedKeywordCount: action.selectedKeyword }
	})),
	on(KeywordActions.updateTotalDailyBudgetUtilized, (state, action) => ({ ...state, budgetUtilized: action.totalBudgetUtilised })),
	on(KeywordActions.updateKeywordForecast, (state, action) => ({
		...state,
		keywordForecast: { estimatedClicks: action.estimatedClicks, estimatedConversions: action.estimatedConversions }
	})),
	on(KeywordActions.clearBudgetAndForecast, (state, action) => ({
		...state,
		budgetUtilized: 0,
		keywordForecast: { estimatedClicks: 0, estimatedConversions: [0, 0] }
	}))
);
