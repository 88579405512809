import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IChannelAsset } from 'src/app/optimise/models/dexter-dashboard.model';
import { getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { GoogleService } from 'src/app/_services/google/google.service';

@Component({
	selector: 'app-campaign-builder-intro',
	templateUrl: './campaign-builder-intro.component.html',
	styleUrls: ['./campaign-builder-intro.component.scss']
})
export class CampaignBuilderIntroComponent implements OnInit {
	public channelsContent: IChannelAsset[] = [
		{
			name: 'facebook',
			title: 'Facebook',
			url: 'assets/icons/Icon-facebook.svg',
			urlDisabled: 'assets/icons/Icon-facebook-disabled.svg',
			enabled: true,
			isActive: true
		},
		{
			name: 'google',
			title: 'Google',
			url: 'assets/icons/google-icon.svg',
			urlDisabled: 'assets/icons/google-gray.svg',
			enabled: true,
			isActive: true
		}
	];

	private unsubscriber$: Subject<void> = new Subject<void>();
	public activeChannel: IChannelAsset[];
	public selectedFBAccount: string = '';
	public googleAccountName: string = '';
	linkToDisplay: string;
	fromGoogle: boolean = false;
	fromFacebook: boolean = false;
	expandMenu: boolean;
	constructor(
		public router: Router,
		public sharedStore: Store<SharedState>,
		private googleService: GoogleService,
		private toastService: ToastNotificationService,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit(): void {
		this.sharedStore.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(selectedAdAccount => {
			const checkAccount = selectedAdAccount?.adAccount?.name;
			if (checkAccount) {
				this.selectedFBAccount = checkAccount;
			}
		});
		this.googleService.selectedGoogleAccount.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (value) {
				this.googleAccountName = value.name?.toString();
			}
		});
		this.authenticationService.expandMenu$.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			this.expandMenu = value;
		});
		this.authenticationService.triggerNavRoute$.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (!value) {
				return;
			} else {
				this.linkToDisplay = value;
				if (this.linkToDisplay === 'google') {
					this.fromGoogle = true;
					this.fromFacebook = false;
				} else if (this.linkToDisplay === 'facebook') {
					this.fromGoogle = false;
					this.fromFacebook = true;
				}
			}
		});
	}

	public moveAction(type: string): void {}

	public chooseChannel(channel: IChannelAsset): void {
		if (!channel.enabled) {
			return;
		}
		if (channel.name === 'facebook') {
			this.router.navigate(['/facebook-campaign-builder']);
		} else if (channel.name === 'google') {
			if (!this.googleService.selectedGoogleAccount.value) {
				this.toastService.sendErrorToast('Please select a google account from the channel selection menu above', 5000);
				return;
			}
			this.router.navigate(['/google-campaign-builder']);
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
